import React from 'react'
import InputField from '../../components/InputField'
import SubmitBtn from '../../components/SubmitBtn'
import { Link } from 'react-router-dom'

type LoginFormProps = {
    email: string
    password: string
    passwordError: boolean
    emailError: boolean
    errorMessage: string
    handleLogin: (e: React.FormEvent) => void
    setEmail: (value: string) => void
    setPassword: (value: string) => void
}

const LoginForm: React.FC<LoginFormProps> = ({
    email,
    password,
    handleLogin,
    setEmail,
    setPassword,
    passwordError,
    emailError,
    errorMessage,
}) => {
    return (
        <form
            onSubmit={handleLogin}
            className="space-y-8 lg:space-y-5 w-[460px] h-auto font-lato font-medium"
        >
            <InputField
                id="email"
                type="email"
                label="Enter your email"
                value={email}
                required
                onChange={(e: any) => setEmail(e.target.value)}
                error={emailError}
            />

            <div>
                <InputField
                    id="password"
                    type="password"
                    label="Enter your password"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    required
                    error={passwordError}
                />
            </div>
            {errorMessage && (
                <p className="text-sm text-red-500">{errorMessage}</p>
            )}
            <div className="flex justify-between items-center my-1 pb-2 text-sm">
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="rememberMe"
                        className="mr-2 w-[18px] h-[18px] accent-primaryBlue"
                    />
                    <label htmlFor="rememberMe" className="">
                        Remember me
                    </label>
                </div>
                <Link to={'#'}>Forgot Password?</Link>
            </div>
            <div className="">
                <SubmitBtn
                    type="submit"
                    label={'Login'}
                    className="w-full rounded-full bg-primaryBlue text-white"
                />
            </div>
        </form>
    )
}

export default LoginForm
