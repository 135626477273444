import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import MainHeading from '@/components/HeroSection/MainHeading'
import { RouteLinks } from '@/constants/RouteLinks'
import { Icon } from '@iconify/react'

interface Subtopic {
    id: number
    name: string
    topics: number
    lessons: number
    hours: number
    quizzes: number
}

interface Subject {
    id: number
    name: string
    topics: number
    lessons: number
    hours: number
    quizzes: number
    description: string
    subtopics: Subtopic[]
}

const SubjectPage: React.FC = () => {
    // const { subjectId } = useParams<{ subjectId: string }>()
    // const [subject, setSubject] = useState<Subject | null>(null)
    // const [loading, setLoading] = useState<boolean>(true)
    // const [error, setError] = useState<string | null>(null)

    const mockSubjectData = {
        id: 1,
        name: 'Core Mathematics',
        topics: 10,
        lessons: 25,
        hours: 40,
        quizzes: 10,
        description:
            'By completing this Core Mathematics course, you’ll strengthen your problem-solving abilities and build a strong foundation in essential math concepts, preparing you for academic success and future opportunities.',
        subtopics: [
            {
                id: 1,
                name: 'Numbers and Numeration',
                topics: 10,
                lessons: 25,
                hours: 40,
                quizzes: 10,
            },
            {
                id: 2,
                name: 'Algebra',
                topics: 8,
                lessons: 18,
                hours: 30,
                quizzes: 10,
            },
            {
                id: 3,
                name: 'Geometry',
                topics: 7,
                lessons: 15,
                hours: 25,
                quizzes: 10,
            },
            {
                id: 4,
                name: 'Trigonometry',
                topics: 5,
                lessons: 12,
                hours: 18,
                quizzes: 10,
            },
            {
                id: 5,
                name: 'Calculus',
                topics: 6,
                lessons: 20,
                hours: 35,
                quizzes: 10,
            },
            {
                id: 6,
                name: 'Statistics and Probability',
                topics: 9,
                lessons: 20,
                hours: 30,
                quizzes: 10,
            },
            {
                id: 7,
                name: 'Linear Algebra',
                topics: 4,
                lessons: 10,
                hours: 15,
                quizzes: 10,
            },
            {
                id: 8,
                name: 'Differential Equations',
                topics: 3,
                lessons: 8,
                hours: 12,
                quizzes: 10,
            },
            {
                id: 9,
                name: 'Matrices and Determinants',
                topics: 5,
                lessons: 15,
                hours: 22,
                quizzes: 10,
            },
            {
                id: 10,
                name: 'Integral Calculus',
                topics: 7,
                lessons: 18,
                hours: 28,
                quizzes: 10,
            },
        ],
    }

    // useEffect(() => {
    //     const fetchSubject = async () => {
    //         try {
    //             const response = await fetch(
    //                 `https://the-api.com/subjects/${subjectId}`
    //             )
    //             if (!response.ok) {
    //                 throw new Error('Failed to fetch subject data')
    //             }
    //             const data = await response.json()
    //             setSubject(data)
    //         } catch (err: any) {
    //             setError(err.message)
    //         } finally {
    //             setLoading(false)
    //         }
    //     }

    //     fetchSubject()
    // }, [subjectId])

    // if (loading) return <div>Loading...</div>
    // if (error) return <div>Error: {error}</div>
    // if (!subject) return <div>No subject found.</div>

    return (
        <>
            <section className="m-5 xl:mx-10">
                <div>
                    <div className="w-full md:w-4/5 xl:w-3/5">
                        <h1 className="text-[32px] font-medium text-bodyText my-1">
                            {mockSubjectData.name}
                        </h1>
                        <p className=" text-base font-lato text-bodyText">
                            {mockSubjectData.description}
                        </p>
                        <p className="text-gray-600 my-6 space-x-2">
                            <span>Topics: {mockSubjectData.topics}</span>,{' '}
                            <span>Lessons: {mockSubjectData.lessons}</span>,{' '}
                            <span>Hours: {mockSubjectData.hours}</span>
                        </p>
                        <div className="flex justify-between items-center lg:w-[70%] ">
                            <div className=" transition-all duration-300 items-center rounded-full h-[6px] bg-gray-200 w-[75%]">
                                <div
                                    className="transition-all duration-300 h-[6px] bg-ctaGreen rounded-full"
                                    style={{
                                        width: `${(mockSubjectData.topics / 10) * 100}%`,
                                    }} // You can calculate progress here
                                ></div>
                            </div>
                            <h2 className=" text-xs lg:text-sm font-medium font-lato">
                                {Math.round(
                                    (mockSubjectData.topics / 10) * 100
                                )}
                                % complete
                            </h2>
                        </div>
                        <button className="my-10">
                            <Link
                                to="#"
                                className="w-[131px] h-10 text-sm font-lato px-4 py-3 bg-primaryBlue text-white rounded-full"
                            >
                                Continue Learning
                            </Link>
                        </button>
                    </div>
                    <section className="my-10 lg:grid lg:grid-cols-2 gap-5">
                        {mockSubjectData.subtopics.map((subtopic) => (
                            <Link
                                key={subtopic.id}
                                className="block border rounded-2xl p-5 h-[145px] lg:h-auto lg:w-[424px] xl:w-[478px] my-5"
                                to={`/subjects/${mockSubjectData.id}/subtopics/${subtopic.id}`}
                            >
                                <MainHeading
                                    text={subtopic.name}
                                    className=" text-[22px] font-medium"
                                />
                                <div className="lg:hidden h-0.5 w-full bg-gray-100 my-5"></div>
                                <div>
                                    <div className="flex justify-between text-gray-600 my-6 space-x-2 pb-5 lg:border-b">
                                        <div>
                                            <div className="flex items-center">
                                                <Icon
                                                    icon="basil:book-outline"
                                                    className="text-2xl mx-1"
                                                />
                                                <p>Topics</p>
                                            </div>

                                            <p className=" text-center">
                                                {subtopic.topics}
                                            </p>
                                        </div>
                                        <div>
                                            <div className=" flex items-center">
                                                <Icon
                                                    icon="fluent:notepad-12-regular"
                                                    className=" text-2xl mx-1"
                                                />
                                                <p>Lessons</p>
                                            </div>
                                            <p className=" text-center">
                                                {subtopic.lessons}
                                            </p>
                                        </div>
                                        <div>
                                            <div className=" flex items-start">
                                                <Icon
                                                    icon="fluent:hourglass-20-regular"
                                                    className=" text-xl mx-1"
                                                />
                                                <p>Hours</p>
                                            </div>
                                            <p className=" text-center">
                                                {subtopic.hours}
                                            </p>
                                        </div>
                                        <div>
                                            <div className=" flex items-center">
                                                <Icon
                                                    icon="fluent:target-arrow-24-filled"
                                                    className=" text-xl mx-1"
                                                />
                                                <p>Quizzes</p>
                                            </div>
                                            <p className=" text-center">
                                                {subtopic.quizzes}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="hidden lg:flex justify-end w-full">
                                        <button className="flex my-1 ">
                                            <Link
                                                to={
                                                    RouteLinks.DASHBOARD_AllPROGRESS
                                                }
                                                className="flex items-center border border-primaryBlue bg-white text-primaryBlue rounded-full px-8 py-1"
                                            >
                                                <span>View Topics</span>{' '}
                                                <Icon
                                                    icon="guidance:left-arrow"
                                                    className="mx-2 text-primaryBlue"
                                                />
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </section>
                </div>
            </section>
        </>
    )
}

export default SubjectPage
