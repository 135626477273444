import React from 'react'
import { Link } from 'react-router-dom'
import { RouteLinks } from '../../../constants/RouteLinks'

const NavLinks: React.FC = () => {
    return (
        <nav className="md:flex gap-3 xl:gap-10 md:ml-[3rem] lg:ml-[5rem] md:mb-3 hidden">
            <Link
                className=" text-[#282828] decoration-none font-medium "
                to={RouteLinks.FEATURES}
            >
                Features
            </Link>
            <Link
                className=" text-[#282828] decoration-none font-medium "
                to={RouteLinks.PRICING}
            >
                Pricing
            </Link>
            <Link
                className=" text-[#282828] decoration-none font-medium"
                to={RouteLinks.ABOUT_US}
            >
                About Us
            </Link>
            <Link
                className=" text-[#282828] decoration-none font-medium "
                to={RouteLinks.CONTACT}
            >
                Contact
            </Link>
        </nav>
    )
}
export default NavLinks
