import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'

interface NavItemProps {
    icon?: string
    label: string
    isOpen: boolean
    link?: string
    className?: string
    path?: string
    onClick?: () => void
}

const NavItem: React.FC<NavItemProps> = ({
    icon,
    label,
    isOpen,
    link,
    className,
    path,
    onClick,
}) => {
    // Check if the icon is a Boxicon or an Iconify icon
    const isBoxicon = icon?.startsWith('bx')

    return (
        <li className="">
            <Link
                to={link ?? '#'}
                className={`flex items-center py-1 cursor-pointer hover:text-primaryBlue ${className}`}
                onClick={onClick}
            >
                {/* Icon container: Ensure it has consistent size */}
                <div className="flex items-center justify-center w-10 h-10">
                    {/* Render Boxicon or Iconify icon */}
                    {icon && isBoxicon ? (
                        <i className={`${icon} ${className} text-2xl`} />
                    ) : icon ? (
                        <Icon
                            icon={icon}
                            className={`${className} text-2xl`}
                            style={{ opacity: 1, visibility: 'visible' }}
                        />
                    ) : null}
                </div>

                {/* Optional Image Rendering */}
                {path ? (
                    <img
                        src={path}
                        className="w-10 h-10 hover:text-primaryBlue"
                    />
                ) : null}

                {/* Label */}
                <span
                    className={`ml-4 text-sm ${className} ${
                        isOpen
                            ? 'transition-opacity duration-700 xl:opacity-100 opacity-0'
                            : 'opacity-0 invisible text-[0px] transition-opacity duration-100'
                    }`}
                >
                    {label}
                </span>
            </Link>
        </li>
    )
}

export default NavItem
