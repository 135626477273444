import React from 'react'

interface MainHeadingProps {
    text: string
    className?: string
}

const MainHeading: React.FC<MainHeadingProps> = ({ text, className }) => {
    return <h1 className={`leading-none ${className}`}>{text}</h1>
}

export default MainHeading
