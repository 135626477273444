import React from 'react'

interface SubHeadingProps {
    text: string
    className: string
}

const SubHeading: React.FC<SubHeadingProps> = ({ text, className }) => {
    return <p className={`my-9 ${className}`}>{text}</p>
}

export default SubHeading
