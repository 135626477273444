import React from 'react'
import lightening from '../../../assets/svg/lightening.svg'
import pointsIcon from '../../../assets/svg/points.svg'
import notifications from '../../../assets/svg/notification_new.svg'
import person from '../../../assets/person1.png'
import projectLogo from '../../../assets/project_logo.png'
import { User } from 'firebase/auth'
import { useLocation } from 'react-router-dom'
import Logo from '@/components/Header/Logo'

type HeaderProps = {
    user: User | null
}

const Header: React.FC<HeaderProps> = ({ user }) => {
    const location = useLocation()

    const paths = location.pathname.split('/')

    const pathName = location.pathname
        .split('/')
        .pop()
        ?.replace(/-/g, ' ')
        .replace(/^\w/, (c) => c.toUpperCase())

    const isMain = location.pathname.endsWith('/dashboard')

    return (
        <div>
            <section className="flex justify-between items-center p-4 bg-white">
                <div className="hidden lg:block font-semibold text-bodyText capitalize">
                    {isMain ? (
                        <div>
                            <h1 className="text-xl xl:text-[22px]">
                                Hey, Welcome back!👋
                            </h1>
                            {user?.displayName && (
                                <h2 className="text-lg font-lato">
                                    {user.displayName}
                                </h2>
                            )}
                        </div>
                    ) : (
                        <h1 className="text-lg xl:text-xl mx-5">{pathName}</h1>
                    )}
                </div>
                <Logo
                    imgUrl={projectLogo}
                    className="w-[133.6px] h-9 lg:hidden"
                />
                <div className="flex items-center space-x-6">
                    <div className="hidden lg:flex  relative">
                        <input
                            type="text"
                            placeholder="What do you want to learn today?"
                            className="border rounded-full p-2 w-[218px] xl:w-[331px] text-sm pl-10"
                        />
                        <div className="absolute top-1 left-2">
                            <i className="bx bx-search-alt text-2xl"></i>
                        </div>
                    </div>
                    <div className="flex items-center gap-5 lg:hidden ">
                        <i className="bx bx-search-alt text-2xl"></i>
                        <i className="bx bx-bell text-2xl"></i>

                        <button
                            type="button"
                            className="flex flex-col cursor-pointer space-y-1 lg:hidden z-30"
                        >
                            <div
                                className={`w-8 h-1 rounded-lg bg-darkText transition-transform duration-300 ease-in-out`}
                            ></div>
                            <div
                                className={`w-8 h-1 rounded-lg bg-darkText transition-opacity duration-300 ease-in-out`}
                            ></div>
                            <div
                                className={`w-8 h-1 rounded-lg bg-darkText transition-transform duration-300 ease-in-out`}
                            ></div>
                        </button>
                    </div>

                    <div className="hidden lg:flex gap-1 text-lg">
                        <img src={lightening} alt="streak" /> <p>{5}</p>
                    </div>
                    <div className="hidden lg:flex gap-1 text-lg">
                        <img src={pointsIcon} /> <p>{1500}</p>
                    </div>
                    <div className="hidden lg:flex ">
                        <img src={notifications} className="" />
                    </div>
                    <div className="hidden lg:flex ">|</div>
                    <div className="hidden lg:flex  gap-2">
                        <img
                            src={person}
                            alt="avatar"
                            className=" object-cover h-9 w-9"
                        />
                        <div className="font-lato text-sm">
                            <p>Peterkin Y.</p>
                            <p>ID: 102222222</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Header
