import React from 'react'
import Header from '../../components/Header'
import HeroSection from '../../components/HeroSection'
import MiddleSection from '../../components/MiddleSection'
import Testimonials from '../../components/Testimonials'
import Footer from '../../components/Footer'
import ParentSection from '../../components/ParentSection'

const Index: React.FC<{}> = () => {
    return (
        <div className=" flex justify-center items-center">
            <div className="overflow-hidden">
                <Header />
                <HeroSection />
                <ParentSection />
                <MiddleSection />
                <Testimonials />
                <Footer />
            </div>
        </div>
    )
}

export default Index
