import React from 'react'
import { Link } from 'react-router-dom'

interface CTAButtonProps {
    label: string
    className?: string
    linkUrl?: string
}

const CTAButton: React.FC<CTAButtonProps> = ({ label, className, linkUrl }) => {
    return (
        <div className={`flex justify-center ${className}`}>
            {linkUrl ? (
                <Link
                    to={linkUrl}
                    className="border-none py-[15px] px-[10px] md:py-[10px] md:px-4 rounded-md cursor-pointer"
                >
                    {label}
                </Link>
            ) : (
                <button
                    type="button"
                    className="border-none py-[15px] px-[10px] md:py-[10px] md:px-4 rounded-md cursor-pointer"
                >
                    {label}
                </button>
            )}
        </div>
    )
}

export default CTAButton
