import MainHeading from '../../HeroSection/MainHeading'
import SubHeading from '../../HeroSection/SubHeading'
import InputField from '../../InputField'
import React, { useEffect, useState } from 'react'
import useDebounce from '../../../hooks/useDebounce'

interface ParentStep1Props {
    wardID: Array<{ id: number; ID: string; status: string }>
    setWardID: React.Dispatch<
        React.SetStateAction<Array<{ id: number; ID: string; status: string }>>
    >
    handleWardIDChange: (value: string, index: number) => void
}

const ParentStep1: React.FC<ParentStep1Props> = ({ wardID, setWardID }) => {
    const [matchingError, setMatchingError] = useState('')

    const [debouncedID, setDebouncedID] = useState('') // To store the current ID that is debounced
    const [currentIndex, setCurrentIndex] = useState<number | null>(null) // To track which index is being debounced

    // Debounce the current ID input
    const debouncedValue = useDebounce(debouncedID, 500)

    // Effect hook to trigger verification when the debounced ID changes
    useEffect(() => {
        window.scrollTo(0, 0)

        if (debouncedValue && currentIndex !== null) {
            const verifyWard = async (ID: string, index: number) => {
                console.log(`Verifying ID: ${ID} at index ${index}`)
                try {
                    // Simulate API call with a delay. just a simulation becaise the api hasn't been integrated
                    console.log('Starting verification...')
                    await new Promise((resolve) => setTimeout(resolve, 1000))

                    // Simulate result of verification (this should be like some 50 50 chance of success or failure)
                    const isValidWard = Math.random() > 0.5
                    console.log(
                        `Verification result: ${isValidWard ? 'Success' : 'Failure'}`
                    )

                    if (isValidWard) {
                        setWardID((prevID) =>
                            prevID.map((ward, idx) =>
                                idx === index
                                    ? { ...ward, status: 'success' }
                                    : ward
                            )
                        )
                    } else {
                        throw new Error('Invalid Ward ID')
                    }
                } catch (error: any) {
                    console.error('Error during verification:', error.message)
                    setMatchingError(
                        'The ID you entered does not exist. Please re-enter your ward’s ID.'
                    )

                    setWardID((prevID) =>
                        prevID.map((ward, idx) =>
                            idx === index ? { ...ward, status: 'error' } : ward
                        )
                    )
                }
            }

            // Trigger the verification
            verifyWard(debouncedValue, currentIndex)
        }
    }, [debouncedValue, currentIndex, setWardID])

    // Handling change for each input
    const handleWardIDChange = (value: string, index: number) => {
        setWardID((prevID) =>
            prevID.map((ward, idx) =>
                idx === index ? { ...ward, ID: value, status: 'pending' } : ward
            )
        )
        //I'd Update and set the current value
        setDebouncedID(value)
        setCurrentIndex(index)
    }

    return (
        <div className="step">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-0 md:mt-[5rem]">
                <MainHeading
                    className="text-[24px] md:text-[28px] text-center leading-[2.5rem] mt-[6rem] lg:mt-[3rem]"
                    text="Link Your Ward's Account."
                />
                <SubHeading
                    text="To monitor your ward’s learning, please enter the unique code they received or their registered email address."
                    className="text-center text-bodyText text-[16px]"
                />
                <div className="flex justify-center">
                    <div className="w-[90%] md:w-[70%] mx-auto">
                        {wardID.map((ward, index) => (
                            <div
                                className="relative w-full lg:mx-auto space-y-5 my-5"
                                key={ward.id}
                            >
                                <InputField
                                    id="ProjectXID"
                                    label="ProjectX ID or email"
                                    type="text"
                                    className={`input-field ${
                                        ward.status === 'success'
                                            ? 'input-success'
                                            : ward.status === 'error'
                                              ? 'input-error'
                                              : ''
                                    }`}
                                    value={ward.ID}
                                    onChange={(e) =>
                                        handleWardIDChange(
                                            e.target.value,
                                            index
                                        )
                                    }
                                    required
                                />
                                {ward.status === 'error' && (
                                    <p className="text-sm text-red-500">
                                        {matchingError}
                                    </p>
                                )}
                                <div className="absolute top-[-10px] right-2">
                                    {ward.status === 'success' && (
                                        <i className="bx bx-check text-2xl text-ctaGreen" />
                                    )}
                                    {ward.status === 'error' && (
                                        <i className="bx bx-x text-2xl text-red-500" />
                                    )}
                                </div>
                            </div>
                        ))}
                        <hr className="text-gray-300" />
                        <button
                            className={`relative flex justify-center items-center space-x-2 w-full py-3 text-sm md:text-base px-4 my-10 mb-20 bg-gray-200 rounded-full text-bodyText`}
                            onClick={() => {
                                setWardID([
                                    ...wardID,
                                    {
                                        id: wardID.length + 1,
                                        ID: '',
                                        status: 'pending',
                                    },
                                ])
                            }}
                        >
                            <i className="bx bx-plus-circle text-2xl"></i>
                            <p>Add another ward Project ID</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParentStep1
