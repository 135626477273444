import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/Dashboard/Sidebar'
import Header from '../../components/Dashboard/Header'
import { useAuth } from 'src/context/AuthContext'

type DashboardProps = {}

const Dashboard: React.FC<DashboardProps> = () => {
    const [isOpen, setIsOpen] = useState(true)
    const { user } = useAuth()
    const toggleSidebar = () => {
        setIsOpen((prev) => !prev)
    }
    const handleResize = () => {
        if (window.innerWidth <= 1280) {
            setIsOpen(false)
        }
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [isOpen])
    return (
        <div className="flex max-w-[100svw] h-svh relative overflow-y-hidden overflow-x-hidden">
            <Sidebar
                isOpen={isOpen}
                toggleSidebar={toggleSidebar}
                //logout={logout}
            />
            <div className="h-svh w-full">
                <Header user={user} />
                <div
                    className={`overflow-y-scroll h-full pb-20 flex-1 transition-all duration-300 ${isOpen ? '' : ''}`}
                >
                    <Outlet context={{ isOpen }} />
                </div>
            </div>
        </div>
    )
}

export default Dashboard
