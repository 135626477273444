import React from 'react'
import { FormData } from '@/types/FormDataTypes'
import MainHeading from '../../HeroSection/MainHeading'
import SubHeading from '../../HeroSection/SubHeading'

interface ParentStep3Props {
    preferredNotification: string[]
    updateFields: (fields: Partial<FormData>) => void
}

const ParentStep3: React.FC<ParentStep3Props> = ({
    preferredNotification,
    updateFields,
}) => {
    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value, checked } = event.target
        // Update the preferredNotification array based on checkbox selection
        if (checked) {
            updateFields({
                preferredNotification: [...preferredNotification, value],
            })
        } else {
            updateFields({
                preferredNotification: preferredNotification.filter(
                    (style) => style !== value
                ),
            })
        }
    }

    return (
        <div className="step">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-0 md:mt-[10rem] xl:mt-[5rem]">
                <MainHeading
                    className="text-[28px] lg:text-center leading-10 mt-[6rem]"
                    text="Set Your Notification Preferences."
                />
                <SubHeading
                    text="Choose how you’d like to receive updates on your child’s progress. You can select more than one option."
                    className="text-center text-bodyText text-[16px]"
                />
                <div className="flex justify-start lg:justify-center">
                    <div className="lg:mx-auto my-10 space-y-5">
                        {[
                            'E-mail notifications',
                            'SMS notifications',
                            'In-App notifications',
                        ].map((notification) => (
                            <div
                                className="flex items-center"
                                key={notification}
                            >
                                <input
                                    type="checkbox"
                                    id={notification
                                        .toLowerCase()
                                        .replace(/\s+/g, '')} // Generate some unique ID
                                    value={notification}
                                    checked={preferredNotification.includes(
                                        notification
                                    )} // Check if selected
                                    onChange={handleCheckboxChange}
                                    className="mx-5 w-6 h-6 accent-primaryBlue"
                                />
                                <label
                                    htmlFor={notification
                                        .toLowerCase()
                                        .replace(/\s+/g, '')}
                                >
                                    {notification}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParentStep3
