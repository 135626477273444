import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../components/Header/Logo'
import MainHeading from '../../components/HeroSection/MainHeading'
import google_icon from '../../assets/Social_google.png'
import LoginForm from '../../components/LoginForm'
import LoadingModal from '../../components/LoadingModal'
import projectLogo from '../../assets/project_logo.png'
import { RouteLinks } from '../../constants/RouteLinks'
import { useNavigate } from 'react-router-dom'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { auth, googleProvider } from 'src/firebaseConfig'

const Login: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsLoading(true)
        setErrorMessage('')
        setEmailError(false)
        setPasswordError(false)

        try {
            console.log('Logging in with:', { email, password })
            const response = await fetch(
                'https://us-central1-project-x-backend-92650.cloudfunctions.net/api/auth/login',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                }
            )

            const data = await response.json()
            console.log('API response:', data)
            if (response.ok && data.token) {
                // Handle successful login
                const bearerToken = data.token
                localStorage.setItem('token', bearerToken)
                console.log('Token stored:', bearerToken)
                console.log('Navigating to dashboard...')
                window.location.href = '/dashboard'
                setIsLoading(false)
            } else {
                // Handle different error responses
                setIsLoading(false)
                setErrorMessage(
                    response.status === 401
                        ? 'Incorrect email or password!'
                        : 'An error occurred. Please try again.'
                )
                if (data.error?.toLowerCase().includes('password')) {
                    setPasswordError(true)
                } else if (data.error?.toLowerCase().includes('email')) {
                    setEmailError(true)
                }
            }
        } catch (error) {
            setIsLoading(false)
            setErrorMessage('A network error occurred. Please try again later.')
            console.error('Login error:', error)
        }
    }

    const signInWithGoogle = async () => {
        try {
            const response = await signInWithPopup(auth, googleProvider)
            if (response) {
                // Handle successful login
                setIsLoading(false)
                const bearerToken = response.user.uid
                localStorage.setItem('token', bearerToken)
                navigate('/dashboard')
            } else {
                // Handle error cases
                setIsLoading(false)
                setErrorMessage('Incorrect email or password!')
            }
        } catch (error) {
            setIsLoading(false)
            setErrorMessage('An error occurred. Please try again later.')
        }
    }

    return (
        <section>
            <div className="mx-auto max-w-[1032px] max-h-screen mb-10">
                <div className="md:flex justify-between items-center w-full md:my-8 md:px-20 xl:px-0 hidden">
                    <Logo imgUrl={projectLogo} className="h-9 w-[133.6px]" />
                    <div className="hidden md:flex justify-end mb-4 p-4">
                        <Link
                            to={RouteLinks.SIGN_UP}
                            className="text-sm text-gray-600"
                        >
                            Don’t have an account yet? 
                            <span className="text-[#24983F] underline">
                                Sign up
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="w-full block md:hidden bg-[#E6F2FF66] p-3">
                    <Logo imgUrl={projectLogo} className="h-11 w-44" />
                </div>
                <MainHeading
                    text="Welcome back!"
                    className="text-center font-medium text-[28px] my-10 md:mt-[0]"
                />

                <div className="flex mx-auto max-w-[460px] md:mt-10 w-11/12 sm:w-[460px]">
                    <button
                        onClick={signInWithGoogle}
                        className="flex justify-center items-center w-full h-14 rounded-full border-2 border-gray-100 hover:bg-gray-100"
                    >
                        <img
                            src={google_icon}
                            className="w-5 h-5 mx-2 text-bodyText text-lg"
                            alt="google button"
                        />
                        Sign Up with Google
                    </button>
                </div>

                <div className="flex max-w-[450px] w-full sm:w-[500px] space-x-1 items-center mx-auto my-8">
                    <strong className="h-0.5 w-full bg-[#f1f1f1]"></strong>
                    <span className="text-bodyText">or</span>
                    <strong className="h-0.5 w-full bg-[#f1f1f1]"></strong>
                </div>
                <div className="flex justify-center mx-auto xl:w-3/5 h-auto px-5">
                    <LoginForm
                        email={email}
                        password={password}
                        setEmail={setEmail}
                        setPassword={setPassword}
                        handleLogin={handleLogin}
                        passwordError={passwordError}
                        emailError={emailError}
                        errorMessage={errorMessage}
                    />
                </div>
                <div className="flex md:hidden justify-center my-5">
                    <Link
                        to={RouteLinks.SIGN_UP}
                        className="text-sm text-gray-600"
                    >
                        Don’t have an account yet?{' '}
                        <span className="text-[#24983F] underline">
                            Sign Up
                        </span>
                    </Link>
                </div>

                {isLoading && <LoadingModal alert="Logging In!" />}
            </div>
        </section>
    )
}

export default Login
