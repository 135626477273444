import React from 'react'
import CTAButton from '../HeroSection/CTAButton'
import Logo from '../Header/Logo'
import SocialsLink from '../SocialsLink'
import { Link } from 'react-router-dom'
import logoSvg from '../../assets/svg/logo.svg'
import youtubeIcon from '../../assets/svg/youtube.svg'
import facebookIcon from '../../assets/svg/facebook.svg'
import twitterIcon from '../../assets/svg/X_twitter.svg'
import telegramIcon from '../../assets/svg/telegram.svg'
import instagramIcon from '../../assets/svg/instagram.svg'
import { RouteLinks } from '../../constants/RouteLinks'

type FooterProps = {}

const Footer: React.FC<FooterProps> = () => {
    return (
        <section className="relative mt-32 py-1" data-testid="footer">
            <div className="px-5 md:px-10 lg:px-16 py-5 absolute z-10 w-full -top-14">
                <div className="bg-gradient-to-r from-[#003D7D] to-[#0059B8] p-5 lg:px-16 rounded-xl flex flex-col md:flex-row gap-4 justify-between items-center">
                    <p className="md:w-[75%] lg:w-[90%] text-white font-semibold text-base sm:text-lg md:text-xl lg:text-4xl">
                        Engage, Learn, and Succeed
                    </p>
                    <div className="flex justify-center items-center w-full gap-3">
                        <CTAButton
                            label="Get Started"
                            className="rounded-full text-sm bg-ctaGreen text-white w-[130px] md:w-[150px]"
                            linkUrl={RouteLinks.SIGN_UP}
                        />
                        <CTAButton
                            label="Contact Us"
                            className="rounded-full text-sm bg-white !text-black w-[130px] md:w-[150px] font-medium"
                        />
                    </div>
                </div>
            </div>
            <div className="bg-[#00346B] h-auto md:h-[361px] relative flex flex-col gap-4 md:flex-row justify-between items-center w-full p-4 md:p-8 pt-36 text-white lg:px-20 ">
                <Logo imgUrl={logoSvg} className="h-11 w-44" />
                <div className="flex justify-between items-center">
                    <nav className="flex flex-wrap gap-2 md:gap-5 font-lato my-5">
                        <Link
                            className="decoration-none font-medium "
                            to="#features"
                        >
                            About Us
                        </Link>
                        <Link
                            className="decoration-none font-medium "
                            to="#pricing"
                        >
                            Careers
                        </Link>
                        <Link
                            className="decoration-none font-medium "
                            to="#about"
                        >
                            FQAs
                        </Link>
                        <Link
                            className="decoration-none font-medium "
                            to="#contact"
                        >
                            Teams
                        </Link>
                        <Link
                            className="decoration-none font-medium "
                            to="#contact"
                        >
                            Contact Us
                        </Link>
                    </nav>
                </div>
                <div className="flex flex-col justify-start items-center gap-2">
                    <div className="text-base">Connect with us!</div>
                    <div className="flex gap-4 ">
                        <SocialsLink iconPath={youtubeIcon} link="#" />
                        <SocialsLink iconPath={facebookIcon} link="#" />
                        <SocialsLink iconPath={twitterIcon} link="#" />
                        <SocialsLink iconPath={telegramIcon} link="#" />
                        <SocialsLink iconPath={instagramIcon} link="#" />
                    </div>
                </div>
            </div>
            <div className="bg-[#00346B] md:flex md:justify-between items-center w-full font-lato border-t border-white text-white py-5 md:py-1 lg:px-20">
                <nav className="flex justify-between w-full md:w-[40%] xl:w-[30%] px-5">
                    <Link className="decoration-none font-medium " to="#policy">
                        Privacy Policy
                    </Link>
                    <Link className="decoration-none font-medium " to="#terms">
                        Terms of Use
                    </Link>
                    <Link className="decoration-none font-medium " to="#legal">
                        Legal
                    </Link>
                </nav>
                <div className="text-center py-5">
                    © 2024 All Rights Reserved
                </div>
            </div>
        </section>
    )
}

export default Footer
