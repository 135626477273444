import { signInWithPopup } from 'firebase/auth'
import { auth, googleProvider } from '../firebaseConfig'
import { toast } from 'react-toastify'

export const handleGoogleSignUp = async (
    onSuccess: () => void,
    userType: string
): Promise<void> => {
    const toastId = toast.loading('Signing you up with Google...')
    setTimeout(() => {
        toast.dismiss(toastId)
    }, 1000)

    try {
        const result = await signInWithPopup(auth, googleProvider)

        // Get the Google ID token
        const token = await result.user.getIdToken()

        // Pass the token to the backend
        const response = await fetch(
            'https://us-central1-project-x-backend-92650.cloudfunctions.net/api/auth/signup/google',
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    role: userType,
                    firstName: result.user.displayName?.split(' ')[0],
                    lastName: result.user.displayName?.split(' ')[1],
                }),
            }
        )

        // Check if the response from the backend is okay
        if (!response.ok) {
            if (response.status === 409) {
                throw new Error('User with this Google account already exists.')
            }
            throw new Error('Failed to sign up with Google.')
        }

        await response.json()

        // If successful, call the onSuccess callback
        onSuccess()

        toast.update(toastId, {
            render: 'Successfully signed up with Google!',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
        })
    } catch (error: any) {
        // Handle specific Firebase errors
        if (error.code === 'auth/popup-closed-by-user') {
            toast.update(toastId, {
                render: 'Google sign-in popup closed by the user.',
                type: 'error',
                isLoading: false,
                autoClose: 4000,
            })
        } else if (error.code === 'auth/network-request-failed') {
            toast.update(toastId, {
                render: 'Network error. Please check your connection and try again.',
                type: 'error',
                isLoading: false,
                autoClose: 4000,
            })
        } else {
            // General error handling for unexpected errors
            toast.update(toastId, {
                render: `Google Sign-Up Failed: ${error.message}`,
                type: 'error',
                isLoading: false,
                autoClose: 4000,
            })
        }
    } finally {
        // Ensure loading state is cleared regardless of the outcome
        toast.clearWaitingQueue()
    }
}
