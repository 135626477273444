import React from 'react'
import MainHeading from '../HeroSection/MainHeading'
import SubHeading from '../HeroSection/SubHeading'
import CarouselCard from '../CarouselCard'
import person1 from '../../assets/person1.png'
import person2 from '../../assets/person2.png'
import person3 from '../../assets/person3.png'
import person4 from '../../assets/person4.png'

type TestimonialsProps = {}

const Testimonials: React.FC<TestimonialsProps> = () => {
    return (
        <section className="p-8 mb-10 " data-testid="testimonials">
            <div className="w-full flex flex-col gap-8">
                <div className="flex flex-col text-center">
                    <MainHeading
                        text="What Our Users Say"
                        className="text-xl lg:text-3xl font-semibold text-center"
                    />
                    <SubHeading
                        text="Real experiences from students, parents, and educators."
                        className="text-sm lg:text-base text-gray-700 text-center"
                    />
                </div>
                <div className="w-auto md:grid grid-cols-2 mx-auto gap-5 items-center">
                    <CarouselCard
                        testimony="“The high-quality content and personalized learning paths have transformed the way I teach. My students are more engaged and prepared.”"
                        imageUrl={person1}
                        name="John Doe"
                        title="Student"
                        from="Ghana Schools"
                    />
                    <CarouselCard
                        testimony="“I love how I can monitor my child's progress through the parent dashboard. The interactive exercises made learning fun. It’s great to see her excel with Project X!”"
                        imageUrl={person2}
                        name="Ama S."
                        title="Parent"
                        from="Accra"
                    />
                    <CarouselCard
                        testimony="“Project X helped me feel confident for my BECE. The interactive exercises made learning fun, and the progress tracking kept me motivated!”"
                        imageUrl={person3}
                        name="Kwame A."
                        title="JHS Student "
                        from="Ghana SHS"
                    />
                    <CarouselCard
                        testimony="“The high-quality content and personalized learning paths have transformed the way I teach. My students are more engaged and prepared.”"
                        imageUrl={person4}
                        name="Mr. Kofi B."
                        title="SHS Teacher"
                        from="Ghana SHS"
                    />
                </div>
            </div>
        </section>
    )
}

export default Testimonials
