import MainHeading from '../../../HeroSection/MainHeading'
import React, { useEffect } from 'react'

interface SHSStudentStep3Props {
    updateFields: (fields: Partial<{ shsProgram: string }>) => void
    shsProgram: string
}

const SHSStudentStep3: React.FC<SHSStudentStep3Props> = ({
    updateFields,
    shsProgram,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="step">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-0 md:mt-[7rem] xl:mt-[5rem]">
                <MainHeading
                    className="text-[24px] md:text-[28px] lg:text-center leading-[2.5rem] mt-[6rem] font-medium"
                    text="Which program are you currently reading?"
                />
                <div className="flex justify-start lg:justify-center font-lato font-medium">
                    <div className="lg:mx-auto my-10 space-y-5">
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="generalScience"
                                name="shsProgram"
                                value="General Science"
                                checked={shsProgram === 'General Science'}
                                onChange={(e) =>
                                    updateFields({ shsProgram: e.target.value })
                                }
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                                required
                            />
                            <label htmlFor="generalScience">
                                General Science
                            </label>
                        </div>

                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="generalArts"
                                name="shsProgram"
                                value="General Arts"
                                checked={shsProgram === 'General Arts'}
                                onChange={(e) =>
                                    updateFields({ shsProgram: e.target.value })
                                }
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="generalArts">General Arts</label>
                        </div>

                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="visualArts"
                                name="shsProgram"
                                value="Visual Arts"
                                checked={shsProgram === 'Visual Arts'}
                                onChange={(e) =>
                                    updateFields({ shsProgram: e.target.value })
                                }
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="visualArts">Visual Arts</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="homeEconomics"
                                name="shsProgram"
                                value="Home Economics"
                                checked={shsProgram === 'Home Economics'}
                                onChange={(e) =>
                                    updateFields({ shsProgram: e.target.value })
                                }
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="homeEconomics">
                                Home Economics
                            </label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="Business"
                                name="shsProgram"
                                value="Business"
                                checked={shsProgram === 'Business'}
                                onChange={(e) =>
                                    updateFields({ shsProgram: e.target.value })
                                }
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="Business">Business</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SHSStudentStep3
