import React, { useState, useEffect } from 'react'
import check_email from '../../assets/check_email.png'
import SubmitBtn from '../SubmitBtn'
import { useAuth } from '../../context/AuthContext'
import { sendEmailVerification } from 'firebase/auth'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

type ModalProps = {
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setEmailVerified: React.Dispatch<React.SetStateAction<boolean>>
    email?: string
}

const Modal: React.FC<ModalProps> = ({
    setIsModalOpen,
    setEmailVerified,
    email,
}) => {
    const { user } = useAuth()
    const [countdown, setCountdown] = useState(60)
    const [isResendActive, setIsResendActive] = useState(false)
    const [isVerifying, setIsVerifying] = useState(false)
    const [error, setError] = useState<string | null>(null)

    // Effect to check email verification status
    useEffect(() => {
        const checkEmailVerification = async () => {
            if (isVerifying || !user) return

            setIsVerifying(true)
            setError(null)

            try {
                await user.reload() // Reload user to update the email verification status
                if (user.emailVerified) {
                    setEmailVerified(true)
                    setIsModalOpen(false)
                }
            } catch (err) {
                toast.error(
                    'An error occurred while checking verification. Please try again.'
                )
            } finally {
                setIsVerifying(false)
            }
        }

        if (user) {
            const interval = setInterval(checkEmailVerification, 5000)
            return () => clearInterval(interval)
        }
    }, [user, setEmailVerified, setIsModalOpen, isVerifying])

    // Countdown logic for the resend button
    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(
                () => setCountdown((prev) => prev - 1),
                1000
            )
            return () => clearTimeout(timer)
        } else {
            setIsResendActive(true)
        }
    }, [countdown])

    // Resend email verification
    const handleResendEmail = async () => {
        if (!user) return

        setCountdown(60)
        setIsResendActive(false)
        setError(null) // Clear any previous errors

        try {
            await sendEmailVerification(user)
        } catch (err) {
            toast.error(
                'Failed to resend verification email. Please try again.'
            )
        }
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity z-10">
            <div className="bg-white p-3 md:p-6 lg:p-12 rounded-lg shadow-lg relative w-[90%] max-w-md">
                <img
                    src={check_email}
                    className="w-20 h-10 md:w-[116px] md:h-[73px]"
                    alt="Check your email"
                />
                <h2 className="text-2xl font-semibold my-2">
                    Confirmation Email Sent
                </h2>
                <p className="text-sm text-gray-600 mb-2">
                    A verification link has been sent to {email}.
                </p>
                <p className="text-sm text-gray-600 mb-2">
                    If it’s not in your inbox, check your spam folder or try
                    resending the email.
                </p>

                <p className="flex space-x-1 items-center text-sm text-bodyText my-4">
                    Haven’t received an email?{'  '}
                    <span
                        className={`mx-1 ${isResendActive ? 'hidden' : 'flex items-center'}`}
                    >
                        You can resend in{' '}
                        <span className="mx-[2px] text-lg">{countdown}s</span>
                    </span>
                </p>
                {/* Resend Button */}
                <SubmitBtn
                    label="Resend"
                    className={`w-full rounded-full ${isResendActive ? 'bg-primaryBlue text-white' : 'bg-gray-100 text-gray-300 cursor-not-allowed'}`}
                    onClick={handleResendEmail}
                    disabled={!isResendActive} // Disable the button until countdown finishes
                />
                {/* Skip for now Button */}
                <SubmitBtn
                    label="Skip for now"
                    className="w-1/2 mx-auto mt-5 bg-gray-100 text-gray-500 rounded-full"
                    onClick={() => setIsModalOpen(false)}
                />
            </div>
        </div>
    )
}

export default Modal
