import React, { useEffect, useState } from 'react'
import Logo from '../../Header/Logo/Logo'
import logoImg from '../../../assets/project_logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import NavItem from '../../NavItem'
import logo_icon from '../../../assets/logo_icon.png'
import { RouteLinks } from '@/constants/RouteLinks'
import { signOut } from 'firebase/auth'
import { auth } from 'src/firebaseConfig'
import { toast } from 'react-toastify'
import { Icon } from '@iconify/react'

interface SidebarProps {
    isOpen: boolean
    toggleSidebar: () => void
    //logout: () => void
}
const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
    const location = useLocation()

    const paths = location.pathname.split('/')

    const pathName = paths[paths.length - 1]

    const navigate = useNavigate()

    const handleSignOut = async () => {
        try {
            await signOut(auth)
            toast.success('Signed out successfully!')
            navigate(RouteLinks.LOGIN)
        } catch (error) {
            console.log(error)
        }
    }

    const navItems = [
        {
            section: 'GENERAL',
            items: [
                {
                    icon: 'ri:home-6-line',
                    label: 'Home',
                    link: RouteLinks.DASHBOARD,
                },
                {
                    icon: 'octicon:book-16',
                    label: 'courses',
                    link: RouteLinks.DASHBOARD_COURSES,
                },
                {
                    icon: 'tabler:target-arrow',
                    label: 'Quizzes & practice',
                    link: RouteLinks.DASHBOARD_QUIZZES,
                },
            ],
        },
        {
            section: 'INSIGHTS',
            items: [
                {
                    icon: 'charm:chart-bar',
                    label: 'Analytics',
                    link: RouteLinks.DASHBOARD_ANALYTICS,
                },
                {
                    icon: 'hugeicons:bubble-chat-notification',
                    label: 'Forum',
                    link: RouteLinks.DASHBOARD_FORUM,
                },
                {
                    icon: 'typcn:folder',
                    label: 'Resources',
                    link: RouteLinks.DASHBOARD_RESOURCES,
                },
                {
                    icon: 'tabler:medal',
                    label: 'Leaderboard',
                    link: RouteLinks.DASHBOARD_LEADERBOARD,
                },
            ],
        },
        {
            section: 'ASSISTANT',
            items: [
                {
                    icon: 'tabler:settings',
                    label: 'Settings',
                    link: RouteLinks.DASHBOARD_SETTINGS,
                },
                {
                    icon: 'flowbite:user-headset-outline',
                    label: 'Support Center',
                    link: RouteLinks.DASHBOARD_SUPPORT,
                },
                {
                    icon: 'tabler:logout-2',
                    label: 'Logout',
                    onClick: handleSignOut,
                },
            ],
        },
    ]

    return (
        <div
            className={`hidden lg:flex flex-col  ${isOpen ? 'xl:w-64 w-14 px-5' : 'w-14 px-2'} bg-[#FAFAFA] text-subText transition-width duration-300`}
        >
            {/* Logo Section */}
            <div
                className={`${isOpen ? 'flex' : ''} items-center justify-between  py-4`}
            >
                <div className="font-semibold">
                    {isOpen ? (
                        <Logo imgUrl={logoImg} className="w-28 h-9" />
                    ) : (
                        <Logo
                            imgUrl={logo_icon}
                            className=" object-cover w-28 h-9"
                        />
                    )}
                </div>
                {isOpen ? (
                    <Icon
                        icon="bi:arrow-bar-left"
                        className="hidden xl:block cursor-pointer text-2xl mb-2"
                        onClick={toggleSidebar}
                    />
                ) : (
                    <Icon
                        icon="bi:arrow-bar-right"
                        className="hidden xl:block cursor-pointer text-2xl mb-2"
                        onClick={toggleSidebar}
                    />
                )}
            </div>
            {/* Navigation Items */}
            <ul className="mt-2">
                {/* Nav Links */}
                {navItems.map((section, index) => (
                    <div key={index}>
                        <div className="flex items-center">
                            <h1
                                className={`mr-1 ${isOpen ? ' hidden xl:block' : 'hidden '}`}
                            >
                                {section.section}
                            </h1>
                            <div className="h-0.5 w-[135px] bg-secondaryText2"></div>
                        </div>
                        {section.items.map((item, idx) => (
                            <NavItem
                                key={idx}
                                icon={item.icon}
                                label={item.label}
                                className={
                                    pathName === item.label
                                        ? `text-primaryBlue ${isOpen ? 'mx-0' : 'mx-1'} capitalize`
                                        : `capitalize ${isOpen ? 'mx-0' : 'mx-1'}`
                                }
                                isOpen={isOpen}
                                link={item.link}
                                onClick={item.onClick}
                            />
                        ))}
                    </div>
                ))}
            </ul>
        </div>
    )
}

export default Sidebar
