import { useEffect } from 'react'
import MainHeading from '../../../HeroSection/MainHeading'

interface JHSStudentStep2Props {
    year: string // from parent component
    updateFields: (fields: Partial<{ year: string }>) => void // to update fields in parent component
}

const JHSStudentStep2: React.FC<JHSStudentStep2Props> = ({
    year,
    updateFields,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="step">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-0 md:mt-[7rem] xl:mt-[5rem]">
                <MainHeading
                    className="text-[24px] md:text-[28px] lg:text-center leading-[2.5rem] mt-[6rem] font-medium"
                    text="Which year are you currently in?"
                />
                <div className="flex justify-start lg:justify-center">
                    <div className="lg:mx-auto my-10 space-y-5 font-lato font-medium">
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="JHS 1"
                                name="year"
                                value="JHS 1"
                                checked={year === 'JHS 1'} // Check if this option is selected
                                onChange={(e) =>
                                    updateFields({ year: e.target.value })
                                } // Update the year in the parent component
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                                required
                            />
                            <label htmlFor="JHS 1">JHS 1</label>
                        </div>

                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="JHS 2"
                                name="year"
                                value="JHS 2"
                                checked={year === 'JHS 2'} // Check if this option is selected
                                onChange={(e) =>
                                    updateFields({ year: e.target.value })
                                } // Update the year in the parent component
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="JHS 2">JHS 2</label>
                        </div>

                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="JHS 3"
                                name="year"
                                value="JHS 3"
                                checked={year === 'JHS 3'}
                                onChange={(e) =>
                                    updateFields({ year: e.target.value })
                                }
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="JHS 3">JHS 3</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JHSStudentStep2
