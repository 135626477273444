import React, { useEffect } from 'react'

type QuizzesPageProps = {}

const QuizzesPage: React.FC<QuizzesPageProps> = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return <div>QuizzesPage</div>
}

export default QuizzesPage
