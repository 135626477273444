import MainHeading from '../../../HeroSection/MainHeading'
import React, { useEffect, useState } from 'react'
import { FormData } from '@/types/FormDataTypes'

interface SHSStudentStep4Props {
    subjects: string[]
    updateFields: (fields: Partial<FormData>) => void
    error: string
}

const SHSStudentStep4: React.FC<SHSStudentStep4Props> = ({
    subjects,
    updateFields,
    error,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value, checked } = event.target
        // Update the subjects array based on checkbox selection
        if (checked) {
            updateFields({ subjects: [...subjects, value] })
        } else {
            updateFields({
                subjects: subjects.filter((subject) => subject !== value),
            })
        }
    }

    return (
        <div className="step">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-0 md:mt-[7rem] xl:mt-[5rem]">
                <MainHeading
                    className="text-[24px] md:text-[28px] lg:text-center leading-[2.5rem] mt-[6rem] font-medium"
                    text="Select the subjects you want to focus on. You can always add more later."
                />
                <div className="flex justify-start lg:justify-center font-lato font-medium">
                    <div className="lg:mx-auto my-10 space-y-5">
                        {[
                            'English',
                            'Core Maths',
                            'Elective Maths',
                            'Biology',
                            'Physics',
                            'Chemistry',
                            'Social Studies',
                        ].map((subject) => (
                            <div className="flex items-center" key={subject}>
                                <input
                                    type="checkbox"
                                    name="shsCourses"
                                    id={subject
                                        .toLowerCase()
                                        .replace(/\s+/g, '')} // Generates some unique id
                                    value={subject}
                                    checked={subjects.includes(subject)} // Check if subject is selected
                                    onChange={handleCheckboxChange}
                                    className="mx-5 w-6 h-6 accent-primaryBlue"
                                />
                                <label
                                    htmlFor={subject
                                        .toLowerCase()
                                        .replace(/\s+/g, '')}
                                >
                                    {subject}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Show em the error message if no subject is selected */}
                {error && (
                    <p className="text-red-500 text-center mt-2">{error}</p>
                )}
            </div>
        </div>
    )
}

export default SHSStudentStep4
