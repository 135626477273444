import MainHeading from '../../HeroSection/MainHeading'
import React from 'react'
import { FormData } from '../../../types/FormDataTypes'

interface StudentStep5Props {
    preferredStudyStyle: string[]
    updateFields: (fields: Partial<FormData>) => void
    error: string
}

const StudentStep5: React.FC<StudentStep5Props> = ({
    preferredStudyStyle,
    updateFields,
    error,
}) => {
    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value, checked } = event.target
        // Update the preferredStudyStyle array based on checkbox selection
        if (checked) {
            updateFields({
                preferredStudyStyle: [...preferredStudyStyle, value],
            })
        } else {
            updateFields({
                preferredStudyStyle: preferredStudyStyle.filter(
                    (style) => style !== value
                ),
            })
        }
    }

    return (
        <div className="step">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-0 md:mt-[10rem] xl:mt-[5rem]">
                <MainHeading
                    className="text-[28px] lg:text-center leading-10 mt-[6rem] font-medium"
                    text="How do you prefer to learn? Choose the formats that suit you best."
                />
                <div className="flex justify-start lg:justify-center font-lato font-medium">
                    <div className="lg:mx-auto my-10 space-y-5">
                        {[
                            'Video Lessons',
                            'Interactive Quizzes',
                            'Reading PDFs',
                            'Practice Tests',
                        ].map((preference) => (
                            <div className="flex items-center" key={preference}>
                                <input
                                    type="checkbox"
                                    id={preference
                                        .toLowerCase()
                                        .replace(/\s+/g, '')} // Generate some unique ID
                                    value={preference}
                                    checked={preferredStudyStyle.includes(
                                        preference
                                    )} // Check if selected
                                    onChange={handleCheckboxChange}
                                    className="mx-5 w-6 h-6 accent-primaryBlue"
                                />
                                <label
                                    htmlFor={preference
                                        .toLowerCase()
                                        .replace(/\s+/g, '')}
                                >
                                    {preference}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Show em the error message if no preferred means is selected */}
                {error && (
                    <p className="text-red-500 text-center mt-2">{error}</p>
                )}
            </div>
        </div>
    )
}

export default StudentStep5
