import React from 'react'
import MainHeading from '../HeroSection/MainHeading'
import SubHeading from '../HeroSection/SubHeading'
import CTAButton from '../HeroSection/CTAButton'
import laptop from '../../assets/laptop.png'
import parent from '../../assets/parent.png'
import mother from '../../assets/mother.png'
import arrow from '../../assets/arrow.png'
import star from '../../assets/star.png'
import sun from '../../assets/sun.png'

type ParentSectionProps = {}

const ParentSection: React.FC<ParentSectionProps> = () => {
    return (
        <div
            className="w-full p-8 lg:px-16 bg-gradient-to-t md:bg-gradient-to-r from-[#CDE5FF] to-[#65AFFF] my-10"
            data-testid="parent-section"
        >
            <div className="flex flex-col md:flex-row-reverse justify-evenly gap-6">
                <div className="flex flex-col flex-1">
                    <MainHeading
                        text="Guiding parents to support success"
                        className="text-2xl text-center md:text-start font-extrabold"
                    />
                    <SubHeading
                        text="Project X is designed for both learners and their guardians. While students engage with personalized, syllabus-aligned content, parents can track their progress every step of the way."
                        className="text-base text-gray-700"
                    />
                    <CTAButton
                        label="Learn more about parent tools"
                        className="w-[15rem] rounded-md font-medium text-sm bg-ctaGreen text-white"
                    />
                    <div className="relative flex items-end justify-end">
                        <img
                            src={laptop}
                            className="absolute size-14 -bottom-14 md:right-36 md:-bottom-16"
                            alt="laptop"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-5 py-8 md:w-[55%]">
                    <div className="relative">
                        <img
                            src={parent}
                            className="w-[12rem] md:w-auto h-auto rounded-t-2xl"
                            alt="parent"
                        />
                        <img
                            src={star}
                            className="size-5 absolute -bottom-9 md:bottom-20 lg:bottom-7 -left-7"
                            alt="star"
                        />
                    </div>
                    <div className="flex justify-center">
                        <img
                            src={arrow}
                            className="size-12 rounded-t-2xl md:rotate-[270deg] md:mt-12"
                            alt="arrow"
                        />
                    </div>
                    <div className="relative flex justify-end pr-7 md:pt-[10vh]">
                        <img
                            src={mother}
                            className="w-[12rem] md:w-auto h-auto rounded-tl-3xl rounded-br-3xl"
                            alt="mother"
                        />
                        <img
                            src={sun}
                            className="size-8 absolute -right-2 -top-7 md:top-12"
                            alt="Sun"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParentSection
