import React from 'react'

interface SubmitBtnProps {
    label: string | React.ReactNode
    className?: string
    onClick?: () => void
    type?: 'button' | 'submit' | 'reset'
    disabled?: boolean
}

const SubmitBtn: React.FC<SubmitBtnProps> = ({
    label,
    className,
    type = 'button',
    onClick,
    disabled = false,
}) => {
    return (
        <button
            type={type}
            className={`flex justify-center border-none py-[15px] px-[10px] md:py-[10px] md:px-4 cursor-pointer ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </button>
    )
}
export default SubmitBtn
