import React from 'react'
import avatar from '../../../assets/svg/Avatar.svg'

type StudentRankProps = {
    rank: number
    medalIcons: { gold: string; silver: string; bronze: string }
    name: string
    points: number
    profile?: string
    className?: string
}

const StudentRank: React.FC<StudentRankProps> = ({
    rank,
    medalIcons,
    name,
    points,
    profile,
    className,
}) => {
    let rankDisplay

    if (rank === 1) {
        rankDisplay = (
            <img src={medalIcons.gold} alt="Gold medal" className="h-9 w-9" />
        )
    } else if (rank === 2) {
        rankDisplay = (
            <img
                src={medalIcons.silver}
                alt="Silver medal"
                className="h-9 w-9"
            />
        )
    } else if (rank === 3) {
        rankDisplay = (
            <img
                src={medalIcons.bronze}
                alt="Bronze medal"
                className="h-9 w-9"
            />
        )
    } else {
        rankDisplay = (
            <span className="font-semibold mx-2 ml-[10px] font-lato text-sm">
                {rank}
            </span>
        )
    }

    return (
        <li className={`flex justify-between font-lato ${className}`}>
            <div className="flex items-center space-x-2">
                {rankDisplay}
                <img
                    src={profile || avatar}
                    alt={`Profile of ${name}`}
                    className="object-cover h-7 w-7 rounded-full"
                />
                <h1 className="text-sm font-semibold">{name}</h1>
            </div>
            <div className="text-sm">
                - <span className="mx-1">{points} pts</span>
            </div>
        </li>
    )
}

export default StudentRank
