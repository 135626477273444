import React from 'react'
import InputField from '../../components/InputField'
import SubmitBtn from '../../components/SubmitBtn'

type SignUpFormProps = {
    firstName: string
    lastName: string
    email: string
    password: string
    passwordError: boolean
    error: boolean
    errorMessage: string
    handleNextClick: (e: React.FormEvent) => void
    setFirstName: (value: string) => void
    setLastName: (value: string) => void
    setEmail: (value: string) => void
    setPassword: (value: string) => void
}

const SignUpForm: React.FC<SignUpFormProps> = ({
    firstName,
    lastName,
    email,
    password,
    passwordError,
    error,
    errorMessage,
    handleNextClick,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
}) => {
    return (
        <form onSubmit={handleNextClick} className="space-y-8 lg:space-y-6">
            <div className="flex gap-4">
                <InputField
                    id="firstName"
                    type="text"
                    label="First Name"
                    value={firstName}
                    required
                    onChange={(e: any) => setFirstName(e.target.value)}
                />
                <InputField
                    id="lastName"
                    type="text"
                    label="Last Name"
                    value={lastName}
                    required
                    onChange={(e: any) => setLastName(e.target.value)}
                />
            </div>
            <InputField
                id="email"
                type="email"
                label="Enter your email"
                value={email}
                required
                onChange={(e: any) => setEmail(e.target.value)}
            />

            <div>
                <InputField
                    id="password"
                    type="password"
                    label="Create password"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    required
                    minLength={8}
                    error={passwordError}
                />
                <p className="text-xs md:text-sm text-gray-500 m-2">
                    Your password should be at least 8 characters long
                </p>
            </div>

            <div>
                {/* Conditionally render the error message or the guidance text */}
                {passwordError || error ? (
                    <p className="text-xs md:text-sm text-red-500 m-2">
                        {errorMessage}
                    </p>
                ) : (
                    ''
                )}
            </div>

            <SubmitBtn
                type="submit"
                label={'Next'}
                className="w-full rounded-full mt-5 bg-primaryBlue text-white"
            />
        </form>
    )
}

export default SignUpForm
