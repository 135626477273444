import React, { useEffect, useState } from 'react'
import Logo from '../Header/Logo'
import projectLogo from '../../assets/project_logo.png'
import CTAButton from '../HeroSection/CTAButton'
import { Link } from 'react-router-dom'
import { RouteLinks } from '@/constants/RouteLinks'

const MenuBtn: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false)

    const handleMenuToggle = () => {
        setIsOpen(!isOpen)
    }
    const handleResize = () => {
        if (window.innerWidth >= 768) {
            setIsOpen(false)
        }
    }
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }
        window.addEventListener('resize', handleResize)
        return () => {
            document.body.classList.remove('overflow-hidden')
            window.removeEventListener('resize', handleResize)
        }
    }, [isOpen])
    return (
        <>
            <Logo
                imgUrl={projectLogo}
                className="absolute left-2 h-9 w-[133.6px] block md:hidden z-40"
            />
            <button
                type="button"
                className="absolute right-[5%] top-[45%] flex flex-col cursor-pointer space-y-1 md:hidden z-30"
                onClick={handleMenuToggle}
            >
                <div
                    className={`w-8 h-1 rounded-lg bg-darkText transition-transform duration-300 ease-in-out ${
                        isOpen ? 'transform rotate-45 translate-y-2' : ''
                    }`}
                ></div>
                <div
                    className={`w-8 h-1 rounded-lg bg-darkText transition-opacity duration-300 ease-in-out ${
                        isOpen ? 'opacity-0' : ''
                    }`}
                ></div>
                <div
                    className={`w-8 h-1 rounded-lg bg-darkText transition-transform duration-300 ease-in-out ${
                        isOpen ? 'transform -rotate-45 -translate-y-2' : ''
                    }`}
                ></div>
            </button>

            {/* Menu */}
            <nav
                className={`${
                    isOpen ? 'block' : 'hidden'
                } bg-white md:bg-transparent w-full md:w-auto absolute md:relative top-0 h-[110vh] left-0 md:space-x-4 p-4 pt-28 md:p-0 z-20 flex flex-col justify-between`}
            >
                <div className=" relative flex-grow">
                    <ul
                        className={` ${
                            isOpen ? 'block' : 'hidden'
                        } list-none md:space-y-0`}
                    >
                        <li className="flex items-center h-full border-t border-gray-200 py-6 h">
                            <Link
                                to="/"
                                className="text-black hover:text-gray-700"
                            >
                                Home
                            </Link>
                        </li>
                        <li className="flex items-center h-full border-t border-gray-200 py-6">
                            <Link
                                to="#about"
                                className="text-black hover:text-gray-700"
                            >
                                About
                            </Link>
                        </li>
                        <li className="flex items-center h-full border-t border-gray-200 py-6">
                            <Link
                                to="#services"
                                className="text-black hover:text-gray-700"
                            >
                                Services
                            </Link>
                        </li>
                        <li className="flex items-center h-full border-t border-gray-200 py-6">
                            <Link
                                to="#contact"
                                className="text-black hover:text-gray-700"
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                    <div className="space-y-5 w-full py-10 border-t">
                        <CTAButton
                            label="Get started free"
                            className="text-xs font-medium rounded-lg bg-ctaGreen text-white"
                            linkUrl={`${RouteLinks.SIGN_UP}`}
                        />
                        <CTAButton
                            label="Log in"
                            className=" bg-white border text-xs font-medium rounded-lg"
                            linkUrl={`${RouteLinks.LOGIN}`}
                        />
                    </div>
                </div>
            </nav>
        </>
    )
}

export default MenuBtn
