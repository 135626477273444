import MainHeading from '@/components/HeroSection/MainHeading'
import React from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import LessonProgressCard from '../LessonProgressCard'
import person from '../../../assets/person1.png'
import globe from '../../../assets/svg/language_24px.svg'
import gold_medal from '../../../assets/svg/Gold 2.svg'
import silver_medal from '../../../assets/svg/Silver 1.svg'
import bronze_medal from '../../../assets/svg/Bronze 1.svg'
import StudentRank from '../StudentRank'
import { RouteLinks } from '@/constants/RouteLinks'
import { Event } from '../../../types/UpcomingTask'
import UpcomingTasks from '../UpcomingTasks'
import avatarUrl from '../../../assets/default-avatar.jpg'

type ProgressViewProps = {}

const ProgressView: React.FC<ProgressViewProps> = () => {
    const lessonData = [
        {
            subjectName: 'Core Maths',
            topic: 'Trigonometry',
            lessonsLeft: 33,
            continueLink: '#',
            progress: '20%',
        },
        {
            subjectName: 'Biology',
            topic: 'Cell Structure and Function',
            lessonsLeft: 12,
            continueLink: '#',
            progress: '45%',
        },
        {
            subjectName: 'Physics',
            topic: 'Electromagnetism',
            lessonsLeft: 9,
            continueLink: '#',
            progress: '60%',
        },
        // {
        //     subjectName: 'English Language',
        //     topic: 'Creative Writing',
        //     lessonsLeft: 5,
        //     continueLink: '#',
        //     progress: '80%',
        // },
        // {
        //     subjectName: 'History',
        //     topic: 'World War II',
        //     lessonsLeft: 15,
        //     continueLink: '#',
        //     progress: '30%',
        // },
        // {
        //     subjectName: 'Chemistry',
        //     topic: 'Organic Compounds',
        //     lessonsLeft: 18,
        //     continueLink: '#',
        //     progress: '25%',
        // },
        // {
        //     subjectName: 'Geography',
        //     topic: 'Climatic Regions',
        //     lessonsLeft: 8,
        //     continueLink: '#',
        //     progress: '50%',
        // },
    ]
    const medalIcons = {
        gold: gold_medal,
        silver: silver_medal,
        bronze: bronze_medal,
    }
    const userId = 7
    const students = [
        { id: 1, name: 'Alice', points: 150, profile: avatarUrl, rank: 1 },
        { id: 2, name: 'Bob', points: 120, profile: avatarUrl, rank: 2 },
        { id: 3, name: 'Charlie', points: 100, profile: avatarUrl, rank: 3 },
        { id: 4, name: 'David', points: 90, profile: avatarUrl, rank: 4 },
        { id: 5, name: 'Eva', points: 80, profile: avatarUrl, rank: 5 },
    ]
    const events: Event[] = [
        {
            date: 'Sept 20',
            title: 'Lesson 1 Revision',
            description: 'Genetics',
        },
        {
            date: 'Sept 22',
            title: 'Lesson 1 Revision',
            description: 'Genetics',
        },
        {
            date: 'Sept 24',
            title: 'Lesson 1 Revision',
            description: 'Genetics',
        },
        {
            date: 'Sept 27',
            title: 'Lesson 1 Revision',
            description: 'Genetics',
        },
    ]

    return (
        <>
            <section className="mx-5 md:mx-10 xl:mx-16 my-8">
                <div className="xl:flex">
                    <div className="xl:w-[70%]">
                        <div className="my-5">
                            <MainHeading
                                text="Progress Overview"
                                className=" text-xl font-semibold text-bodyText "
                            />
                        </div>
                        <div>
                            {lessonData.map((lesson, index) => (
                                <LessonProgressCard
                                    key={index}
                                    subjectName={lesson.subjectName}
                                    topic={lesson.topic}
                                    lessonsLeft={lesson.lessonsLeft}
                                    continueLink={lesson.continueLink}
                                    progress={lesson.progress}
                                />
                            ))}
                        </div>
                        <button className="flex mx-auto my-1">
                            <Link
                                to={RouteLinks.DASHBOARD_AllPROGRESS}
                                className="border border-primaryBlue bg-white text-primaryBlue rounded-full px-8 py-1"
                            >
                                View All
                            </Link>
                        </button>
                        <div className="lg:hidden h-0.5 bg-gray-200 w-full my-10"></div>
                        <div className="my-5 mt-10">
                            <MainHeading
                                text="Leaderboard Highlights"
                                className=" text-xl font-semibold text-bodyText"
                            />
                        </div>
                        <div className=" border rounded-lg p-5">
                            <div className="">
                                <div className=" flex justify-between mb-10">
                                    <img
                                        src={person}
                                        alt="avatar"
                                        className=" object-cover h-10 w-10"
                                    />
                                    <img
                                        src={globe}
                                        alt="globe"
                                        className=" object-cover h-6 w-6 m-1"
                                    />
                                </div>
                                <div className=" flex justify-between text-sm font-semibold text-bodyText font-lato my-2">
                                    <p>Rank # {7}</p>
                                    <p className="">Top 1%</p>
                                </div>
                                <div className="flex justify-between items-center font-lato text-right text-subText">
                                    <p>{90000} points</p>
                                    <p className=" text-xs font-normal">
                                        Out of 4,848 students
                                    </p>
                                </div>
                            </div>
                            <div className=" h-0.5 w-full mx-auto bg-gray-200 my-10"></div>
                            <ul>
                                {students.map((student) => (
                                    <StudentRank
                                        key={student.id}
                                        rank={student.rank}
                                        medalIcons={medalIcons}
                                        name={student.name}
                                        points={student.points}
                                        profile={student.profile}
                                        className={`py-7 px-3 border-b ${student.id === userId ? 'border-b-4 border-4  border-primaryBlue' : ''}`}
                                    />
                                ))}
                            </ul>
                            <button className="flex mx-auto my-1">
                                <Link
                                    to={RouteLinks.DASHBOARD_LEADERBOARD}
                                    className="bg-primaryBlue text-white rounded-full text-sm px-2 py-1 my-5"
                                >
                                    View All Rankings
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="md:hidden h-0.5 w-full bg-gray-200 my-16 mx-auto"></div>
                    <div className="md:flex xl:block md:mx-5 xl:mx-10 xl:border-l my-5 h-fit ">
                        <div className="xl:ml-10 md:w-1/2 xl:w-full">
                            <div className="my-5 mt-8 xl:mt-0">
                                <MainHeading
                                    text="Upcoming Tasks"
                                    className=" text-xl font-semibold text-bodyText"
                                />
                            </div>
                            <div className="border rounded-lg mx-auto">
                                <UpcomingTasks events={events} />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-full lg:mx-10 my-16 md:mt-6">
                            <div className="md:hidden xl:block h-0.5 bg-gray-200 my-10 mx-auto"></div>
                            <h1 className=" text-xl font-semibold lg:my-1 text-bodyText">
                                Quick Links
                            </h1>
                            <div className="lg:flex justify-between w-full lg:w-3/4 xl:w-full">
                                <Link
                                    to={''}
                                    className="flex items-center my-4 mx-2 p-2 rounded-md border w-full"
                                >
                                    <i className="bx bx-trophy text-2xl"></i>
                                    <p className="text-sm font-lato">
                                        Achievements
                                    </p>
                                </Link>
                                <Link
                                    to={''}
                                    className="flex items-center my-4 mx-2 p-2 rounded-md border w-full"
                                >
                                    <i className="bx bx-file text-2xl"></i>
                                    <p className="text-sm font-lato">
                                        Resources
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProgressView
