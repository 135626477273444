import React, { useState } from 'react'

type InputFieldProps = {
    label: string
    type: string
    placeholder?: string
    value?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: () => void
    className?: string
    required?: boolean
    minLength?: number
    id: string
    error?: boolean
}

const InputField: React.FC<InputFieldProps> = ({
    label,
    type,
    placeholder,
    value,
    onChange,
    className,
    required,
    minLength,
    id,
    error = false,
}) => {
    // Track the focus state and whether the input has any value at all
    const [isFocused, setIsFocused] = useState(false)

    // Handle input focus and blur events
    const handleFocus = () => setIsFocused(true)
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!e.target.value) setIsFocused(false)
    }

    return (
        <div className="relative w-full">
            <label
                htmlFor={id}
                className={`absolute left-3 px-1 bg-white transition-all duration-300 ease-in-out 
                    ${isFocused || value ? 'top-[-10px] text-xs' : 'top-[13px]'}
                    ${error ? 'text-red-500' : isFocused || value ? 'text-blue-500' : 'text-gray-400'}
                `}
                style={{ pointerEvents: 'none' }} // Allows clicking through the label
            >
                {label}
            </label>

            {/* Input Field with id */}
            <input
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required={required}
                minLength={minLength}
                className={`w-full p-3 border ${
                    error ? 'border-red-500' : 'border-gray-300'
                } rounded-lg focus:outline-none focus:border-${error ? 'red' : 'blue'}-500 ${className} pr-10`}
                placeholder={placeholder}
            />
            {/* Exclamation Icon */}
            {error && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    {/* You can replace this with any icon library or custom SVG */}
                    <svg
                        className="h-5 w-5 text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M18 10c0 4.418-3.582 8-8 8S2 14.418 2 10 5.582 2 10 2s8 3.582 8 8zm-9-4a1 1 0 112 0v4a1 1 0 11-2 0V6zm1 7a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
            )}
        </div>
    )
}

export default InputField
