import React, { createContext, useContext, useEffect, useState } from 'react'
import { auth } from '../firebaseConfig'
import { User, onAuthStateChanged, signOut } from 'firebase/auth'

type AuthContextType = {
    user: User | null
    emailVerified: boolean
    isLoading: boolean
    //logout: () => Promise<void>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [user, setUser] = useState<User | null>(null)
    const [emailVerified, setEmailVerified] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setIsLoading(false)
            if (currentUser) {
                setUser(currentUser)
                // Checking if email is verified
                setEmailVerified(!!currentUser.emailVerified)
            } else {
                setUser(null)
                setEmailVerified(false)
            }
            setIsLoading(false)
        })

        return () => unsubscribe() //this is just to clean up the listener whe the component unmount
    }, [])
    // const logout = async () => {
    //     setIsLoading(true)
    //     await signOut(auth)
    //     window.location.href = '/login'
    //     setIsLoading(false)
    // }

    return (
        <AuthContext.Provider value={{ user, emailVerified, isLoading }}>
            {!isLoading && children}
        </AuthContext.Provider>
    )
}

// Hook to use the AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
