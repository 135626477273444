export enum RouteLinks {
    LOGIN = '/login',
    SIGN_UP = '/signup',
    FEATURES = '#features',
    PRICING = '#pricing',
    ABOUT_US = '#about',
    CONTACT = '#contact',
    DASHBOARD = '/dashboard',
    DASHBOARD_COURSES = '/dashboard/courses',
    DASHBOARD_QUIZZES = '/dashboard/quizzes',
    DASHBOARD_ANALYTICS = '/dashboard/analytics',
    DASHBOARD_FORUM = '/dashboard/forum',
    DASHBOARD_LEADERBOARD = '/dashboard/leaderboard',
    DASHBOARD_RESOURCES = '/dashboard/RESOURCES',
    DASHBOARD_SETTINGS = '/dashboard/settings',
    DASHBOARD_SUPPORT = '/dashboard/support',
    DASHBOARD_ACHIEVEMENTS = '/dashboard/achievements',
    DASHBOARD_AllPROGRESS = '/dashboard/allprogress',
    DASHBOARD_NewSubject = '/dashboard/newsubject',
}
