import React from 'react'
import MainHeading from '../HeroSection/MainHeading'
import checkCircle from '../../assets/Check circle.png'
import SubHeading from '../HeroSection/SubHeading'

type GotoDashboardProps = {}

const GotoDashboard: React.FC<GotoDashboardProps> = () => {
    return (
        <div className="step">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-[18vh] md:mt-[10rem] xl:mt-[5rem]">
                <MainHeading
                    className="text-[28px] text-center leading-10 font-medium"
                    text="You're all set!"
                />
                <div className="flex flex-col justify-center">
                    <div className="mx-auto my-10 space-y-5">
                        <img
                            src={checkCircle}
                            className="h-10 w-10 md:h-24 md:w-24"
                        />
                    </div>
                    <SubHeading
                        text="You’re all set! Based on your preferences, we’ve tailored a learning plan just for you. Let's start learning!"
                        className="text-center text-bodyText text-[16px] font-lato font-medium"
                    />
                </div>
            </div>
        </div>
    )
}

export default GotoDashboard
