import React from 'react'
import MainHeading from './MainHeading'
import Subheading from './SubHeading'
import CTAButton from './CTAButton'
import styles from './HeroSection.module.css'
import heroImg_1 from '../../assets/heroImg_1.png'
import heroImg_2 from '../../assets/heroImg_2.png'
import heroImg_3 from '../../assets/heroImg_3.png'
import vector_1 from '../../assets/Vector_1.png'
import vector_2 from '../../assets/Vector_2.png'
import vector_3 from '../../assets/Vector_3.png'
import vector_4 from '../../assets/Pink divider tool.png'
import vector_5 from '../../assets/01.png'
import targetImg from '../../assets/target.png'
import notebookImg from '../../assets/notebook.png'
import { RouteLinks } from '../../constants/RouteLinks'

const HeroSection: React.FC = () => {
    return (
        <>
            <section data-testid="hero-section">
                <section
                    className={`${styles.heroSection} w-full h-full pt-[8rem] pb-[12rem] md:py-[12rem]  md:h-fit flex flex-col gap-4 md:flex-row px-8 lg:px-16`}
                >
                    <div className="md:w-[44%] h-fit relative ">
                        <MainHeading
                            text="Empowering Every Student, Every Step"
                            className="text-[1.5rem] md:text-[2.5rem] lg:text-[4.8vw] text-center md:text-left font-bold leading-normal lg:leading-[5.2vw] text-[#222222] -tracking-normal md:tracking-tighter"
                        />
                        <img
                            src={notebookImg}
                            className="absolute w-[6vw] lg:w-auto left-1 h-[3vh] md:h-[auto] top-11 md:-left-12 md:-top-8 lg:-top-16"
                        />
                        <img
                            src={vector_5}
                            className="absolute w-[8vw] h-[2vh] lg:w-auto lg:h-auto top-10 right-3 md:-right-[4vw] lg:-right-[5vw]"
                        />
                        <Subheading
                            className="text-[1rem] lg:text-[1.3rem] text-center md:text-left text-[#575757]"
                            text="Comprehensive exam prep with tools for students, support for parents, and insights for educators. From practice tests to progress tracking, everything you need to succeed in BECE & WASSCE."
                        />
                        <div className="flex items-center justify-center md:justify-start">
                            <CTAButton
                                label="Get Started"
                                linkUrl={RouteLinks.SIGN_UP}
                                className="px-8 bg-ctaGreen hover:bg-[#28a746dc] text-white rounded-lg"
                            />
                        </div>
                        <img
                            src={targetImg}
                            className="absolute w-[7vw] lg:w-[4vw] h-[3.5vh] bottom-4 md:-bottom-28 right-0 md:right-4 md:h-[auto]"
                        />
                    </div>
                    <div className="w-full md:w-[56%]">
                        <div className="relative w-full">
                            <div className=" flex justify-center">
                                <img
                                    src={heroImg_1}
                                    className="w-[65vw] md:w-[40vw] lg:w-[27vw] h-auto md:absolute -top-[80px] z-10 lg:h-auto mt-5 md:mt-0"
                                    alt="student 1"
                                />
                                <img
                                    src={vector_4}
                                    className="absolute left-0 top-2 w-[6.5vw] lg:w-[4vw] md:-right-5 md:left-auto md:top-24 lg:top-[22vh]"
                                />
                            </div>
                            <div className="flex items-center justify-center gap-20 py-2 md:mt-[15.5vh] lg:mt-[31vh]">
                                <img src={vector_1} className="size-9" />
                                <img src={vector_2} className="size-12" />
                            </div>

                            <img
                                src={heroImg_3}
                                className="absolute w-[38vw] -right-3 lg:right-[7.5rem] md:w-[25vw] lg:w-[13vw] md:top-[20vh] lg:top-[35vh] z-10 h-auto"
                                alt="student 3"
                            />
                            <img
                                src={vector_3}
                                className="absolute left-[32vw] md:left-[20vw] lg:left-[23vw] top-[18.8rem] size-11 xl:size-13 md:top-[28vh] lg:top-[45vh]"
                            />
                            <img
                                src={heroImg_2}
                                className="absolute w-[38vw] md:w-[25vw] md:top-[21vh] lg:top-[37vh] lg:w-[13vw] -left-7 z-10 h-auto lg:left-[6rem]"
                                alt="student 2"
                            />
                        </div>
                    </div>
                </section>
                <section className="py-10 md:p-8 lg:px-16">
                    <div className="w-full flex flex-col gap-3">
                        <p className="px-8 md:pt-6 text-sm md:px-4">
                            Trusted by students at over 100 schools:
                        </p>
                        <div className="w-full flex justify-between md:justify-evenly shadow-md md:rounded-md border-t gap-11 border-gray-100 text-bodyText px-8 py-4 md:p-10">
                            <div className=" font-semibold text-base md:text-2xl text-center">
                                <p>Accra</p>
                                <p className="">Schools</p>
                            </div>
                            <div className=" font-semibold text-base md:text-2xl text-center">
                                <p>Kumasi</p>
                                <p>Schools</p>
                            </div>
                            <div className=" font-semibold text-base md:text-2xl text-center">
                                <p>Takoradi</p>
                                <p>Schools</p>
                            </div>
                            <div className="hidden md:block font-semibold text-base md:text-2xl text-center">
                                <p>Tema</p>
                                <p>Schools</p>
                            </div>
                            <div className="hidden md:block font-semibold text-base md:text-2xl text-center">
                                <p>Cape Coast </p>
                                <p>Schools</p>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default HeroSection
