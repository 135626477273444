import React from 'react'
import { Link } from 'react-router-dom'

type SocialsLinkProps = {
    iconPath: string
    link: string
}

const SocialsLink: React.FC<SocialsLinkProps> = ({ iconPath, link }) => {
    return (
        <Link
            className="flex justify-center items-center rounded-full w-6 h-6  transition-all cursor-pointer"
            to={link}
        >
            <img src={iconPath} alt="socials" />
        </Link>
    )
}

export default SocialsLink
