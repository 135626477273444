import React from 'react'

type LoadingModalProps = {
    alert: string
}

const LoadingModal: React.FC<LoadingModalProps> = ({ alert }) => {
    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 transition-opacity z-10"
            role="dialog"
        >
            <div className="flex justify-center bg-white p-6 lg:p-12 rounded-lg shadow-lg relative w-[90%] max-w-md">
                <svg
                    className="animate-spin h-5 w-5 mr-2 text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    role="img"
                >
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="4"
                        d="M12 2 A10 10 0 0 1 22 12"
                    />
                </svg>

                <h1>{alert}</h1>
            </div>
        </div>
    )
}

export default LoadingModal
