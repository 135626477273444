import React from 'react'
import MainHeading from '../HeroSection/MainHeading'
import Subheading from '../HeroSection/SubHeading'
import content from '../../assets/content.png'
import learning from '../../assets/learning.png'
import exercise from '../../assets/exercise.png'
import tracking from '../../assets/tracking.png'
import arrow2 from '../../assets/Vector_5.png'
import arrow1 from '../../assets/Vector_4.png'

type MiddleSectionProps = {}

const MiddleSection: React.FC<MiddleSectionProps> = () => {
    return (
        <section data-testid="middle-section">
            <div className="flex flex-col gap-14 p-8 lg:px-[20vw]">
                <div className="md:flex justify-between relative items-center">
                    <div>
                        <MainHeading
                            text="Your Pathway to Success"
                            className="text-xl lg:text-3xl font-semibold text-center"
                        />
                        <Subheading
                            className="text-sm lg:text-base text-gray-700 text-center"
                            text="Empower your academic journey with a platform designed to deliver excellence. Explore the key features that make Project X your ultimate partner in BECE & WASSCE success."
                        />
                    </div>
                </div>
                <div className="md:flex justify-between relative items-center md:gap-16">
                    <div>
                        <MainHeading
                            text="High Quality Content"
                            className="text-xl lg:text-3xl font-semibold text-center md:text-left"
                        />
                        <Subheading
                            className="text-sm lg:text-base text-gray-700 text-center md:text-left"
                            text="Access a rich library of expertly crafted content, meticulously aligned with Ghana’s BECE and WASSCE syllabi. From engaging videos to comprehensive PDFs, every resource is designed to deepen your understanding and boost your confidence."
                        />
                    </div>
                    <div className="flex justify-center md:flex-none">
                        <img
                            src={content}
                            alt="High Quality Content"
                            className="h-auto"
                        />
                    </div>
                    <img
                        src={arrow1}
                        className="absolute top-28 -right-[13rem] size-72 hidden lg:block"
                        alt="arrow 1"
                    />
                </div>
                <div className="flex flex-col md:flex-row-reverse relative items-center md:gap-16">
                    <div>
                        <MainHeading
                            text="Personalized Learning"
                            className="text-xl lg:text-3xl font-semibold text-center md:text-left"
                        />
                        <Subheading
                            className="text-sm lg:text-base text-gray-700 text-center md:text-left"
                            text="Your learning experience is tailored to fit your unique needs and goals, guiding you on a path to success."
                        />
                    </div>
                    <div className="flex justify-center">
                        <img
                            src={learning}
                            className="h-auto"
                            alt="Personalized Learning"
                        />
                    </div>
                    <img
                        src={arrow2}
                        className="absolute top-28 -left-[13.5rem] size-72 hidden lg:block"
                        alt="arrow 2"
                    />
                </div>
                <div className="md:flex justify-between relative items-center md:gap-16">
                    <div>
                        <MainHeading
                            text="Progress Tracking"
                            className="text-xl lg:text-3xl font-semibold text-center md:text-left"
                        />
                        <Subheading
                            className="text-sm lg:text-base text-gray-700 text-center md:text-left"
                            text="Easily monitor your progress, set goals, and stay motivated with real-time tracking of your achievements."
                        />
                    </div>
                    <div className="flex justify-center md:flex-none">
                        <img
                            src={tracking}
                            alt="Progress Tracking"
                            className="h-auto"
                        />
                    </div>

                    <img
                        src={arrow1}
                        className="absolute top-28 -right-[13rem] size-72 hidden lg:block"
                        alt="arrow 1"
                    />
                </div>
                <div className="flex flex-col md:flex-row-reverse md:gap-16">
                    <div>
                        <MainHeading
                            text="Interactive Exercises"
                            className="text-xl lg:text-3xl font-semibold text-center md:text-left"
                        />
                        <Subheading
                            className="text-sm lg:text-base text-gray-700 text-center md:text-left"
                            text="Enhance your learning with interactive quizzes and practice exams designed to make studying engaging and effective."
                        />
                    </div>

                    <div className="flex justify-center">
                        <img
                            src={exercise}
                            alt="Interactive Exercises"
                            className="h-auto"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MiddleSection
