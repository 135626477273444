import { Routes, Route, createBrowserRouter } from 'react-router-dom'

import Home from './pages/Home'
import SignUp from './pages/SignUp'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import DashboardHome from './components/Dashboard/Home'
import SubjectPage from './components/Dashboard/SubjectPage'
import Courses from './components/Dashboard/Courses'
import QuizzesPage from './components/Dashboard/QuizzesPage'
import AnalyticsPage from './components/Dashboard/AnalyticsPage'
import ForumsPage from './components/Dashboard/ForumsPage'
import ResourcesPage from './components/Dashboard/ResourcesPage'
import LeaderboardPage from './components/Dashboard/LeaderboardPage'
import SettingsPage from './components/Dashboard/SettingsPage'
import SupportPage from './components/Dashboard/SupportPage'
import ProtectedRoute from './components/ProtectedRoute'
import ProgressView from './components/Dashboard/PogressView'
import AllProgressPage from './components/Dashboard/AllProgressPage.tsx'
import Achievements from './components/Dashboard/Achievements'
import NewSubject from './components/Dashboard/NewSubject'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/signup',
        element: <SignUp />,
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
            {
                index: true,
                element: <DashboardHome />,
            },
            {
                path: 'courses',
                element: <Courses />,
            },
            {
                path: 'quizzes',
                element: <QuizzesPage />,
            },
            {
                path: 'analytics',
                element: <AnalyticsPage />,
            },
            {
                path: 'forum',
                element: <ForumsPage />,
            },
            {
                path: 'resources',
                element: <ResourcesPage />,
            },
            {
                path: 'leaderboard',
                element: <LeaderboardPage />,
            },
            {
                path: 'settings',
                element: <SettingsPage />,
            },
            {
                path: 'support',
                element: <SupportPage />,
            },
            {
                path: 'progressView',
                element: <ProgressView />,
            },
            {
                path: 'allProgress',
                element: <AllProgressPage />,
            },
            {
                path: 'achievements',
                element: <Achievements />,
            },
            {
                path: 'subjects/:subjectId',
                element: <SubjectPage />,
            },
            {
                path: 'newsubject',
                element: <NewSubject />,
            },
        ],
    },
])
