import React from 'react'
import CTAButton from '../../HeroSection/CTAButton'
import { RouteLinks } from '../../../constants/RouteLinks'

const AuthButtons: React.FC = () => {
    return (
        <div className="hidden md:flex items-center space-x-4 -mt-1">
            <CTAButton
                linkUrl={RouteLinks.LOGIN}
                label={'Login'}
                className="text-bodyText font-medium transition-all"
            />
            <CTAButton
                linkUrl={RouteLinks.SIGN_UP}
                label={'Get Started'}
                className="bg-ctaGreen text-white rounded-md transition-all hover:bg-[#28a746dc]"
            />
        </div>
    )
}

export default AuthButtons
