import { Icon } from '@iconify/react'
import React, { useState } from 'react'

type Lesson = {
    title: string
    duration: string
}

type Topic = {
    id: number
    title: string
    lessonsCount: number
    videosCount: number
    lessons: Lesson[]
}

type AccordionProps = {
    topics: Topic[]
}

const Accordion: React.FC<AccordionProps> = ({ topics }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null)

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index)
    }

    return (
        <div className="mx-auto border rounded-lg ">
            {topics.map((topic, index) => (
                <div key={topic.id} className="border-b rounded-t-lg">
                    <button
                        onClick={() => toggleAccordion(index)}
                        className={`flex justify-between items-center w-full text-left ${index === 0 ? 'rounded-t-lg' : ''}  p-4 ${
                            activeIndex === index ? 'bg-[#F0F7FF]' : 'bg-white'
                        }`}
                    >
                        <div>
                            <h3 className="font-semibold font-lato text-bodyText">
                                {topic.title}
                            </h3>
                            <p className="text-sm text-secondaryText">
                                {topic.lessonsCount} Lessons •{' '}
                                {topic.videosCount} Videos
                            </p>
                        </div>

                        <Icon
                            icon="lucide:chevron-up"
                            className={`transform transition-transform ${
                                activeIndex === index
                                    ? 'rotate-180'
                                    : 'rotate-0'
                            }`}
                        />
                    </button>
                    {activeIndex === index && (
                        <div className="p-4">
                            {topic.lessons.map((lesson, lessonIndex) => (
                                <div
                                    key={lessonIndex}
                                    className="flex justify-between  items-center p-4 border-b font-semibold font-lato text-secondaryText"
                                >
                                    <p>{lesson.title}</p>
                                    <p className="text-secondaryText text-xs">
                                        {lesson.duration}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default Accordion
