import { ReactElement, useState } from 'react'

function useMultistepForm(steps: ReactElement[]) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const isFirstStep = currentStepIndex === 0
    const isLastStep = currentStepIndex === steps.length - 1

    function next() {
        setCurrentStepIndex((i) => {
            if (i >= steps.length - 1) return i
            return i + 1
        })
    }

    function back() {
        setCurrentStepIndex((i) => {
            if (i <= 0) return i
            return i - 1
        })
    }

    function goTo(index: number) {
        setCurrentStepIndex(index)
    }

    const validationRules = {
        2: {
            requiredField: 'subjects',
            errorMessage: 'Please select at least one of the above.',
        },
        3: {
            requiredField: 'preferredStudyStyle',
            errorMessage: 'Please select at least one of the above.',
        },
        4: {
            requiredField: 'preferredNotification',
            errorMessage: 'Please select at least one of the above.',
        },
    }

    return {
        currentStepIndex,
        step: steps[currentStepIndex],
        steps,
        isFirstStep,
        isLastStep,
        goTo,
        next,
        back,
        validationRules,
    }
}
export default useMultistepForm
