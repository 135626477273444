import { useEffect } from 'react'
import MainHeading from '../../../HeroSection/MainHeading'

interface SHSStudentStep2Props {
    year: string // Current year value from parent component
    updateFields: (fields: Partial<{ year: string }>) => void
}

const SHSStudentStep2: React.FC<SHSStudentStep2Props> = ({
    year,
    updateFields,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="step">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-0 md:mt-[7rem] xl:mt-[5rem]">
                <MainHeading
                    className="text-[24px] md:text-[28px] lg:text-center leading-[2.5rem] mt-[6rem] font-medium"
                    text="Which year are you currently in?"
                />
                <div className="flex justify-start lg:justify-center font-lato font-medium">
                    <div className="lg:mx-auto my-10 space-y-5">
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="SHS 1"
                                name="year"
                                value="SHS 1"
                                checked={year === 'SHS 1'} // Check if this option is selected
                                onChange={(e) =>
                                    updateFields({ year: e.target.value })
                                } // Update the year in the parent component
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                                required
                            />
                            <label htmlFor="SHS 1">SHS 1</label>
                        </div>

                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="SHS 2"
                                name="year"
                                value="SHS 2"
                                checked={year === 'SHS 2'}
                                onChange={(e) =>
                                    updateFields({ year: e.target.value })
                                }
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="SHS 2">SHS 2</label>
                        </div>

                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="SHS 3"
                                name="year"
                                value="SHS 3"
                                checked={year === 'SHS 3'}
                                onChange={(e) =>
                                    updateFields({ year: e.target.value })
                                }
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="SHS 3">SHS 3</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SHSStudentStep2
