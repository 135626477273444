import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import mathIcon from '../../../assets/illustrations/calc.png'

type CoursesProps = {}

const Courses: React.FC<CoursesProps> = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const subjects = [
        {
            id: 1,
            name: 'Core Mathematics',
            topics: 44,
            lessons: 323,
            hours: 80,
        },
        { id: 2, name: 'Biology', topics: 44, lessons: 323, hours: 80 },
        {
            id: 3,
            name: 'Integrated Science',
            topics: 44,
            lessons: 323,
            hours: 80,
        },
        { id: 4, name: 'French', topics: 44, lessons: 323, hours: 80 },
        { id: 5, name: 'I.C.T', topics: 44, lessons: 323, hours: 80 },
    ]

    return (
        <div className="w-full flex justify-center items-center overflow-x-hidden">
            <div className="lg:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 justify-items-center w-full mx-auto max-w-[100%]">
                {subjects.map((subject) => (
                    <div
                        key={subject.id}
                        className="rounded-2xl transition-all duration-300 my-4 lg:my-4"
                    >
                        <div
                            className="min-h-80 w-full lg:w-[441px] rounded-2xl transition-all duration-300 flex items-end"
                            style={{
                                backgroundImage: `url(${mathIcon})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                        >
                            <div className="w-full rounded-2xl bg-white bg-opacity-30 backdrop-blur-2xl p-4">
                                <p className="flex items-center text-sm text-bodyText my-2">
                                    {subject.topics} Topics
                                    <i className="bx bxs-circle text-[6px] mx-2"></i>{' '}
                                    {subject.lessons} Lessons
                                    <i className="bx bxs-circle text-[6px] mx-2"></i>
                                    {subject.hours} Hours
                                </p>
                                {/* Progress Bar */}
                                <div className="flex items-center">
                                    <div className="flex-1 bg-white rounded-full h-2 relative overflow-hidden mr-2">
                                        <div className="bg-green-500 h-2 rounded-full w-[20%]"></div>
                                    </div>
                                    <span className="text-bodyText text-sm">
                                        20%
                                    </span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <Link
                                        to={`/dashboard/subjects/${subject.id}`}
                                        className="flex justify-center items-center mt-2 bg-white py-2 px-4 rounded-md w-[146px] text-xs"
                                    >
                                        {subject.name}
                                    </Link>
                                    <Link
                                        to={`/dashboard/subjects/${subject.id}`}
                                        className="flex justify-center items-center mt-2 bg-black text-white py-2 px-4 rounded-md w-[146px] text-xs"
                                    >
                                        Go to Course
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Courses
