import MainHeading from '../../HeroSection/MainHeading'
import SubHeading from '../../HeroSection/SubHeading'
import React from 'react'
import empty_inbox from '../../../assets/Empty Inbox.png'

interface ParentStep2 {}

const ParentStep2: React.FC<ParentStep2> = () => {
    return (
        <div className="step">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-[18vh] md:mt-[5rem]">
                <MainHeading
                    className="text-[24px] md:text-[28px] text-center leading-[2.5rem] mt-[6rem]"
                    text="Request Sent for Approval"
                />
                <SubHeading
                    text="A request has been sent to your ward’s account for approval. Your ward will need to confirm this request via their Project X account or registered email before the link is complete."
                    className="text-center text-bodyText text-[16px]"
                />
                <div className="flex flex-col justify-center">
                    <div className="mx-auto my-5">
                        <img
                            src={empty_inbox}
                            className="h-24 w-24 md:h-72 md:w-72"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParentStep2
