import React from 'react'
import { Event } from '../../../types/UpcomingTask'
import { Icon } from '@iconify/react'

interface UpcomingTasksProps {
    events: Event[]
}

const UpcomingTasks: React.FC<UpcomingTasksProps> = ({ events }) => {
    return (
        <div className="rounded-lg p-5 w-full">
            <div className="relative">
                {events.map((event, index) => (
                    <ul key={index} className="flex items-center mb-6 relative">
                        <li className="flex items-center w-full my-3">
                            <div className="relative w-[40%] m-2 mx-4">
                                {index !== 0 && (
                                    <div className="absolute flex flex-col mx-auto -top-[75px] space-y-1 ml-2 font-lato">
                                        <i className="bx bx-circle text-[6px] mx-2 text-[#bababa]"></i>
                                        <h1 className="w-0.5 h-10 bg-[#bababa] mx-[10px]"></h1>
                                        <i className="bx bx-circle text-[6px] mx-2 text-[#BABABA]"></i>
                                    </div>
                                )}
                                {event.date}
                            </div>
                            <div className="flex items-center border rounded-lg p-2 w-full font-lato font-medium space-x-2">
                                <Icon
                                    icon="hugeicons:task-daily-02"
                                    className="text-2xl"
                                />
                                <div>
                                    <h1 className="text-sm">{event.title}</h1>
                                    <h2 className="text-xs text-gray-400">
                                        {event.description}
                                    </h2>
                                </div>
                            </div>
                        </li>
                    </ul>
                ))}
            </div>
        </div>
    )
}

export default UpcomingTasks
