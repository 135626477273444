import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { handleGoogleSignUp } from '../../auth/googleAuth'
import Logo from '../../components/Header/Logo'
import google_icon from '../../assets/Social_google.png'
import pencil_img from '../../assets/pencil_img_2.png'
import logoImg from '../../assets/project_logo.png'
import MainHeading from '../../components/HeroSection/MainHeading'
import Modal from '../../components/Modal'
import PersonalizationForm from '../../components/PersonalizationForm'
import SignUpForm from '../../components/SignUpForm'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from 'src/firebaseConfig'
import { toast } from 'react-toastify'
import LoadingModal from '../../components/LoadingModal'
import { RouteLinks } from '../../constants/RouteLinks'
import useScrollToTop from '../../hooks/useScrollTotop'

type SignUpProps = {}

const SignUp: React.FC<SignUpProps> = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [emailVerified, setEmailVerified] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordError, isPasswordError] = useState(false)
    const [error, isError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    // State to determine if the user is a student or a parent
    const [userType, setUserType] = useState<'student' | 'parent'>('student')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useScrollToTop()
    // Function to toggle between student and parent
    const toggleUserType = (type: 'student' | 'parent') => {
        if (type !== userType) {
            setTimeout(() => {
                setUserType(type)
            }, 100) // duration matches the animation duration
        }
    }

    const handleEmailVerified = () => {
        setEmailVerified(true)
    }

    const onGoogleSignUp = async () => {
        setIsLoading(true)
        try {
            await handleGoogleSignUp(handleEmailVerified, userType)
        } catch (error: any) {
            toast.error(`Google Sign-Up Failed: ${error.message}`)
        } finally {
            setIsLoading(false)
        }
    }

    const handleNextClick = async (e: React.FormEvent) => {
        e.preventDefault()

        // Password validation
        if (password.length < 8) {
            isPasswordError(true)
            setErrorMessage('Password must be at least 8 characters long.')
            return
        }

        const userData = {
            email,
            password,
            firstName,
            lastName,
            role: userType,
        }

        setIsLoading(true)
        try {
            const response = await fetch(
                'https://us-central1-project-x-backend-92650.cloudfunctions.net/api/auth/signup',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userData),
                }
            )

            if (!response.ok) {
                const errorResponse = await response.json()
                handleErrorResponse(response.status, errorResponse.message)
            }

            await response.json()

            // toast.success('Sign-up successful! Please verify your email.')

            await signInWithEmailAndPassword(auth, email, password)
            setIsModalOpen(true)
        } catch (error) {
            if (
                error instanceof TypeError &&
                error.message === 'Failed to fetch'
            ) {
                isError(true)
                setErrorMessage('Network error. Please check your connection.')
                toast.error('Network error. Please check your connection.')
            } else {
                isError(true)
                setErrorMessage('An unknown error occurred. Try again')
                console.error('Sign-up Error:', error)
            }
        } finally {
            setIsLoading(false)
        }
    }

    function handleErrorResponse(status: number, message?: string) {
        let errorMessage = 'Failed to sign up. Please try again.'

        if (status === 400) {
            if (message?.includes('already exists')) {
                errorMessage =
                    'This account is already registered. Please log in instead.'
            } else {
                errorMessage =
                    message ||
                    'Invalid request. Please check your data and try again.'
            }
        } else if (status === 409) {
            errorMessage = 'User with this Google account already exists.'
        } else if (status >= 500) {
            errorMessage = 'Server error, please try again later.'
        }

        throw new Error(errorMessage)
    }
    // Once the email is verified, i'll render the PersonalizationForm
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex justify-between w-full">
                {/* Left section */}
                <div className="hidden md:flex flex-col h-svh relative bg-[#FCFCFC] pt-5 w-full xl:max-w-[419px] 2xl:max-w-[30%]">
                    <div className="p-4">
                        <Logo imgUrl={logoImg} className="h-9 w-[133.6px]" />
                        <div className="flex py-4 px-2">
                            {userType === 'student' ? (
                                <ul className="mt-4 space-y-4 font-lato font-bold">
                                    <li className="flex items-center text-left text-sm text-bodyText">
                                        <i className="bx bxs-check-circle text-2xl text-primaryBlue mr-2"></i>
                                        Access high-quality study materials and
                                        notes
                                    </li>
                                    <li className="flex items-center text-left text-sm text-bodyText">
                                        <i className="bx bxs-check-circle text-2xl text-primaryBlue mr-2"></i>
                                        Learn through videos, quizzes, and
                                        interactive exercises
                                    </li>
                                    <li className="flex items-center text-left text-sm text-bodyText">
                                        <i className="bx bxs-check-circle text-2xl text-primaryBlue mr-2"></i>
                                        Track my progress and set study goals
                                    </li>
                                    <li className="flex items-center text-left text-sm text-bodyText">
                                        <i className="bx bxs-check-circle text-2xl text-primaryBlue mr-2"></i>
                                        Track my progress and set study goals
                                    </li>
                                </ul>
                            ) : userType === 'parent' ? (
                                <ul className="mt-4 space-y-4  font-lato font-bold">
                                    <li className="flex items-center text-sm text-bodyText">
                                        <i className="bx bxs-check-circle text-2xl text-primaryBlue mr-2"></i>
                                        Monitor my child's study progress and
                                        performance
                                    </li>
                                    <li className="flex items-center text-sm text-bodyText">
                                        <i className="bx bxs-check-circle text-2xl text-primaryBlue mr-2"></i>
                                        Receive alerts when my child completes a
                                        course or reaches a milestone
                                    </li>
                                    <li className="flex items-center text-sm text-bodyText">
                                        <i className="bx bxs-check-circle text-2xl text-primaryBlue mr-2"></i>
                                        Provide my child with high-quality
                                        learning materials
                                    </li>
                                    <li className="flex items-center text-sm text-bodyText">
                                        <i className="bx bxs-check-circle text-2xl text-primaryBlue mr-2"></i>
                                        Access to detailed reports and insights
                                        on my child's learning activities
                                    </li>
                                </ul>
                            ) : null}
                        </div>
                    </div>
                    <div className="flex-grow w-full">
                        <img
                            src={pencil_img}
                            className=" object-cover w-full h-full xl:h-[80vh] 2xl:h-screen rounded-tl-[350px] xl:rounded-tl-[320px] 2xl:rounded-tl-full"
                            alt="pencil image"
                        />
                        {/* <div
                            className="w-full h-full rounded-tl-[300px] bg-cover bg-center"
                            style={{ backgroundImage: `url(${pencil_img})` }}
                        ></div> */}
                    </div>
                </div>

                {/* Right side */}
                {emailVerified ? (
                    <div className="flex flex-col w-full md:w-[70%] p-5 ml-auto">
                        <PersonalizationForm userType={userType} />
                    </div>
                ) : (
                    <div className="flex flex-col w-full md:w-[90%] xl:w-[70%] md:p-8 ml-auto">
                        <div className="hidden md:flex justify-end mb-4 p-4">
                            <Link
                                to={RouteLinks.LOGIN}
                                className="text-sm text-gray-600"
                            >
                                Already have an account?{' '}
                                <span className="text-[#24983F] underline">
                                    Log in
                                </span>
                            </Link>
                        </div>
                        <div className="w-full block md:hidden bg-[#E6F2FF66] p-3">
                            <Logo
                                imgUrl={logoImg}
                                className="h-9 w-[133.6px]"
                            />
                        </div>

                        <div className="md:mt-12 xl:mt-0">
                            <MainHeading
                                text="How would you like to use Project X?"
                                className="w-5/6 lg:w-full mx-auto text-2xl md:text-[28px] text-center my-5 mt-[5%] md:mt-[3%] lg:mt-0 leading-relaxed lg:leading-loose font-medium"
                            />
                            <div className="flex justify-center mb-4 bg-[#F8FBFF] mx-auto px-4 py-2 rounded-[30px] relative overflow-hidden max-w-[335px]">
                                {/* Toggle Background */}
                                <div
                                    className={`absolute left-0 w-1/2 text-center bg-white h-[80%] bottom-[5px] mx-2 rounded-[30px] transition-transform border-[1px] border-[#EEEEEE] duration-100 ${
                                        userType === 'student'
                                            ? 'transform translate-x-0'
                                            : 'transform translate-x-[90%]'
                                    }`}
                                />
                                {/* Buttons */}
                                <button
                                    className={`relative z-10 text-sm text-left md:text-normal px-4 py-2 -left-6 rounded-full ${
                                        userType === 'student'
                                            ? 'text-secondaryText'
                                            : 'text-gray-600'
                                    }`}
                                    onClick={() => toggleUserType('student')}
                                >
                                    I am a Student
                                </button>
                                <button
                                    className={`relative z-10 px-4 py-2 -right-6 rounded-full ${
                                        userType === 'parent'
                                            ? 'text-secondaryText'
                                            : 'text-gray-600'
                                    }`}
                                    onClick={() => toggleUserType('parent')}
                                >
                                    I am a Parent
                                </button>
                            </div>
                            <div className="flex mx-auto max-w-[530px] mt-10 w-11/12 sm:w-[500px] my-3">
                                <button
                                    onClick={onGoogleSignUp}
                                    className="flex justify-center items-center w-full h-14 rounded-full border-2 border-gray-100 hover:bg-gray-100"
                                >
                                    <img
                                        src={google_icon}
                                        className="w-5 h-5 mx-2 text-bodyText text-lg"
                                        alt="google button"
                                    />
                                    Sign Up with Google
                                </button>
                            </div>
                            <div className="flex max-w-[530px] w-full sm:w-[480px] space-x-1 items-center mx-auto my-3">
                                <strong className="h-0.5 w-full bg-[#f1f1f1]"></strong>
                                <span className="text-bodyText">or</span>
                                <strong className="h-0.5 w-full bg-[#f1f1f1]"></strong>
                            </div>

                            {/* Conditional Rendering Based on User Type */}
                            <div className="flex justify-center mx-auto xl:w-3/5 px-5">
                                <SignUpForm
                                    firstName={firstName}
                                    lastName={lastName}
                                    email={email}
                                    password={password}
                                    handleNextClick={handleNextClick}
                                    setFirstName={setFirstName}
                                    setLastName={setLastName}
                                    setEmail={setEmail}
                                    setPassword={setPassword}
                                    errorMessage={errorMessage}
                                    passwordError={passwordError}
                                    error={error}
                                />
                            </div>
                            <div className="flex md:hidden justify-center my-5">
                                <Link
                                    to={RouteLinks.LOGIN}
                                    className="text-sm text-gray-600"
                                >
                                    Already have an account?{' '}
                                    <span className="text-[#24983F] underline">
                                        Log in
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Conditionally render modal */}
            {isModalOpen && (
                <Modal
                    setIsModalOpen={setIsModalOpen}
                    setEmailVerified={setEmailVerified}
                    email={email}
                />
            )}

            {isLoading && <LoadingModal alert="Signing Up!" />}
        </div>
    )
}

export default SignUp
