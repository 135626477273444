import React, { useEffect } from 'react'
import MainHeading from '../../HeroSection/MainHeading'

interface StudentStep1Props {
    educationLevel: string
    updateFields: (fields: Partial<{ educationLevel: string }>) => void
}

const StudentStep1: React.FC<StudentStep1Props> = ({
    educationLevel,
    updateFields,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!educationLevel) {
            updateFields({ educationLevel: 'Junior High School (JHS)' })
        }
    }, [educationLevel, updateFields])
    return (
        <div className="step flex justify-center items-center">
            <div className="w-[100%] md:w-[90%] lg:w-[80%] mx-auto mt-0 md:mt-[2rem] xl:mt-[5rem]">
                <MainHeading
                    className="text-[24px] md:text-[28px] md:text-center leading-[2.5rem] mt-[6rem] lg:mt-[2rem] font-medium"
                    text="What's your current level of education?"
                />
                <div className="flex justify-start lg:justify-center font-lato font-medium">
                    <div className="lg:mx-auto my-10 space-y-5">
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="junior"
                                name="education"
                                value="Junior High School (JHS)"
                                checked={
                                    educationLevel ===
                                    'Junior High School (JHS)'
                                }
                                onChange={(e) =>
                                    updateFields({
                                        educationLevel: e.target.value,
                                    })
                                }
                                required
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="junior">
                                Junior High School (JHS)
                            </label>
                        </div>

                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="senior"
                                name="education"
                                value="Senior High School (SHS)"
                                checked={
                                    educationLevel ===
                                    'Senior High School (SHS)'
                                }
                                required
                                onChange={(e) =>
                                    updateFields({
                                        educationLevel: e.target.value,
                                    })
                                }
                                className="mx-5 w-6 h-6 accent-primaryBlue"
                            />
                            <label htmlFor="senior">
                                Senior High School (SHS)
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentStep1
