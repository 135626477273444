import React from 'react'
import Logo from './Logo'
import NavLinks from './NavLinks'
import AuthButtons from './AuthButtons'
import MenuBtn from '../MenuBtn'
import logoImg from '../../assets/project_logo.png'

const Header: React.FC = () => {
    return (
        <header
            className="absolute w-full flex -mt-5 md:mt-5 py-10 px-2 xl:px-24 max-w-[110rem]"
            data-testid="header"
        >
            <div className="flex md:w-[70%] xl:w-2/3 items-center">
                <Logo imgUrl={logoImg} className="h-9 w-[133.6px] " />
                <NavLinks />
            </div>
            <div className="ml-auto mb-1">
                <AuthButtons />
                <MenuBtn />
            </div>
        </header>
    )
}

export default Header
