import React from 'react'

type CarouselCardProps = {
    testimony: string
    imageUrl: string
    name: string
    from?: string
    title: string
}

const CarouselCard: React.FC<CarouselCardProps> = ({
    testimony,
    imageUrl,
    name,
    from,
    title,
}) => {
    return (
        <article className="w-auto relative bg-[#F5F9FE] p-8 sm:max-w-[345px] md:max-w-[535px] min-h-[253px] mt-20">
            <div className="flex justify-between w-full">
                <p className="pt-5 text-base text-gray-700">{testimony}</p>
                <img
                    src={imageUrl}
                    className="rounded-full size-20 absolute -top-10"
                    alt="person"
                />
            </div>
            <div className="mt-10 flex gap-5 justify-between w-full items-start">
                <p className="font-semibold">{name}</p>
                <p className="text-base text-gray-700 text-right">
                    <span className="block">{title}</span>
                    <span className="block">{from}</span>
                </p>
            </div>
        </article>
    )
}

export default CarouselCard
