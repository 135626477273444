import React, { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useMultistepForm from '../../hooks/useMultiStepForm'
import { FormData, UserType } from '../../types/FormDataTypes'
import StudentStep1 from './StudentSteps/StudentStep1'
import JHSStudentStep2 from './StudentSteps/JHSStudentSteps/JHSStudentStep2'
import SHSStudentStep2 from './StudentSteps/SHSStudentSteps/SHSStudentStep2'
import GotoDashboard from './GotoDashboard'
import SHSStudentStep3 from './StudentSteps/SHSStudentSteps/SHSStudentStep3'
import JHSStudentStep3 from './StudentSteps/JHSStudentSteps/JHSStudentStep3'
import StudentStep5 from './StudentSteps/StudentStep5'
import SHSStudentStep4 from './StudentSteps/SHSStudentSteps/SHSStudentStep4'
import Logo from '../Header/Logo'
import SubmitBtn from '../SubmitBtn'
import ParentStep1 from './ParentSteps/ParentStep1'
import ParentStep2 from './ParentSteps/ParentStep2'
import ParentStep3 from './ParentSteps/ParentStep3'
import logoImg from '../../assets/project_logo.png'

const INITIAL_DATA: FormData = {
    educationLevel: '',
    year: '',
    subjects: [],
    shsProgram: '',
    shsCourses: [],
    preferredStudyStyle: [],
    linkedWards: [{ id: 1, ID: '', status: 'pending' }],
    preferredNotification: [],
}

interface PersonalizationFormProps {
    userType: UserType
}

const PersonalizationForm: React.FC<PersonalizationFormProps> = ({
    userType,
}) => {
    const [data, setData] = useState(INITIAL_DATA)
    const [error, setError] = useState('')
    const [wardID, setWardID] = useState([{ id: 1, ID: '', status: 'pending' }])
    const navigate = useNavigate()

    const updateFields = (fields: Partial<FormData>) => {
        setData((prev) => {
            // If the user switches from JHS to SHS or vice versa, I'll clear specific fields
            if (
                fields.educationLevel &&
                fields.educationLevel !== prev.educationLevel
            ) {
                if (fields.educationLevel === 'Junior High School (JHS)') {
                    return {
                        ...prev,
                        ...fields,
                        shsProgram: '',
                        subjects: [],
                        shsCourses: [],
                        preferredStudyStyle: [],
                    }
                } else if (
                    fields.educationLevel === 'Senior High School (SHS)'
                ) {
                    return {
                        ...prev,
                        ...fields,
                        subjects: [],
                        preferredStudyStyle: [],
                    }
                }
            }

            // Default behavior, I just update fields normally
            return {
                ...prev,
                ...fields,
            }
        })
    }

    // Logic to update wardID for the parent flow
    const handleWardIDChange = (value: string, index: number) => {
        setWardID((prevID) =>
            prevID.map((ward, idx) =>
                idx === index ? { ...ward, ID: value, status: 'pending' } : ward
            )
        )
    }

    const steps = () => {
        if (userType === 'parent') {
            return [
                <ParentStep1
                    wardID={wardID}
                    setWardID={setWardID}
                    handleWardIDChange={handleWardIDChange}
                />,
                <ParentStep2 />,
                <ParentStep3
                    preferredNotification={data.preferredNotification}
                    updateFields={updateFields}
                />,
                <GotoDashboard />,
            ]
        } else {
            // for when userType === 'student'
            const studentSteps = [
                <StudentStep1 {...data} updateFields={updateFields} />,
            ]

            if (data.educationLevel === 'Junior High School (JHS)') {
                studentSteps.push(
                    <JHSStudentStep2 {...data} updateFields={updateFields} />,
                    <JHSStudentStep3
                        subjects={data.subjects}
                        updateFields={updateFields}
                        error={error}
                    />,
                    <StudentStep5
                        preferredStudyStyle={data.preferredStudyStyle}
                        updateFields={updateFields}
                        error={error}
                    />
                )
            } else if (data.educationLevel === 'Senior High School (SHS)') {
                studentSteps.push(
                    <SHSStudentStep2 {...data} updateFields={updateFields} />,
                    <SHSStudentStep3 {...data} updateFields={updateFields} />,
                    <SHSStudentStep4
                        subjects={data.subjects}
                        updateFields={updateFields}
                        error={error}
                    />,
                    <StudentStep5
                        preferredStudyStyle={data.preferredStudyStyle}
                        updateFields={updateFields}
                        error={error}
                    />
                )
            }
            studentSteps.push(<GotoDashboard />) //Final step for the students flow
            return studentSteps
        }
    }

    const {
        steps: stepComponents,
        currentStepIndex,
        step,
        isFirstStep,
        isLastStep,
        back,
        next,
        goTo,
    } = useMultistepForm(steps())

    const handleSkip = () => {
        goTo(stepComponents.length - 2)
    }

    function onSubmit(e: FormEvent) {
        e.preventDefault()

        if (userType === 'student') {
            const isJHSSubjectStep =
                data.educationLevel === 'Junior High School (JHS)' &&
                currentStepIndex === 2
            const isSHSSubjectStep =
                data.educationLevel === 'Senior High School (SHS)' &&
                currentStepIndex === 3
            if (
                (isJHSSubjectStep || isSHSSubjectStep) &&
                data.subjects.length === 0
            ) {
                setError('Please select at least one subject.')
                return
            }

            const isJHSStudyStyleStep =
                data.educationLevel === 'Junior High School (JHS)' &&
                currentStepIndex === 3 // based on the actual step index for JHS
            const isSHSStudyStyleStep =
                data.educationLevel === 'Senior High School (SHS)' &&
                currentStepIndex === 4 // based on the actual step index for SHS
            if (
                (isJHSStudyStyleStep || isSHSStudyStyleStep) &&
                data.preferredStudyStyle.length === 0
            ) {
                setError('Please select at least one preferred study style.')
                return
            }
        }

        setError('')

        if (currentStepIndex === stepComponents.length - 2) {
            // Sync wardID with linkedWards before submission(might not be necessary)
            setData((prevData) => ({
                ...prevData,
                linkedWards: wardID,
            }))
            const dataToLog = {
                ...data,
                role: userType,
            }

            // Log the data that will be submitted to the backend so we see what we have so far
            console.log(
                'Submitting the following data to the backend:',
                dataToLog
            )

            return next() // This is supposed to moved the user to the GotoDashboard screen
        }

        // Proceed to the next step if not the last step
        next()
    }

    const goToDashboard = () => {
        console.log('Final step reached, navigating to dashboard')
        navigate('/dashboard')
    }
    const parentStep3Index = 2
    const disableBack =
        isFirstStep ||
        isLastStep ||
        (currentStepIndex === parentStep3Index && userType === 'parent')
    // Progress bar percentage
    const progressPercentage =
        ((currentStepIndex + 1) / stepComponents.length) * 100
    return (
        <>
            <div className="relative multi-step-form h-[90vh]">
                <form onSubmit={onSubmit}>
                    <div className="absolute w-[100vw] left-[-1.25rem] -top-[1.5rem] block md:hidden bg-gray-100 p-3">
                        <Logo imgUrl={logoImg} />
                    </div>
                    <div>
                        {/* Progress Bar */}
                        <div className="absolute top-[8vh] md:top-[4vh] left-[14vw] sm:left-[7vw] lg:left-[10vw] transition-all duration-300 flex w-[80%] rounded-full h-[6px] bg-gray-200">
                            <div
                                className="transition-all duration-300 h-[6px] bg-ctaGreen rounded-full"
                                style={{ width: `${progressPercentage}%` }}
                            />
                        </div>
                        {step}
                        <div className="form-navigation ">
                            <div className="md:hidden lg:block absolute top-[6.5vh] md:top-[2vh] -left-[3vw] md:-left-[1vw] lg:left-[1vw]">
                                {!isFirstStep &&
                                    !isLastStep &&
                                    !disableBack && (
                                        <button
                                            type="button"
                                            onClick={back}
                                            className="prev-btn flex items-center space-x-1 lg:space-x-3"
                                        >
                                            <i className="bx bx-chevron-left text-xl lg:text-2xl"></i>
                                            <h1>Back</h1>
                                        </button>
                                    )}
                            </div>
                            <div className="flex justify-center md:h-auto">
                                <div className="absolute w-full -bottom-8 md:relative md:bottom-auto flex justify-center">
                                    {isLastStep ? (
                                        <SubmitBtn
                                            type="button"
                                            onClick={goToDashboard}
                                            label={'Go to dashboard'}
                                            className="bg-primaryBlue text-white w-[90%] md:w-[460px] mx-auto rounded-full"
                                        />
                                    ) : (
                                        <SubmitBtn
                                            type="submit"
                                            label={'Continue'}
                                            className="bg-primaryBlue text-white w-[90%] md:w-[460px] mx-auto rounded-full"
                                        />
                                    )}
                                    {/* Skip Button visible only on ParentStep1 */}
                                    {userType === 'parent' &&
                                        currentStepIndex === 0 && (
                                            <div className="absolute -bottom-12">
                                                <button
                                                    type="button"
                                                    onClick={handleSkip}
                                                    className="text-bodyText rounded"
                                                >
                                                    Skip
                                                </button>
                                            </div>
                                        )}
                                    {/** Back button on Tablet screen size */}
                                    <div className="hidden md:flex lg:hidden absolute top-[10vh] md:top-[8vh] md:left-[12vw]">
                                        {!isFirstStep &&
                                            !isLastStep &&
                                            !disableBack && (
                                                <button
                                                    type="button"
                                                    onClick={back}
                                                    className="prev-btn flex items-center space-x-1 lg:space-x-3"
                                                >
                                                    <i className="bx bx-chevron-left text-xl lg:text-2xl"></i>
                                                    <h1>Back</h1>
                                                </button>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default PersonalizationForm
