import CTAButton from '@/components/HeroSection/CTAButton'
import React from 'react'
import { Link } from 'react-router-dom'

type LessonProgressCardProps = {
    subjectName: string
    topic: string
    lessonsLeft: number
    continueLink: string
    progress: string
    className?: string
}

const LessonProgressCard: React.FC<LessonProgressCardProps> = ({
    progress,
    subjectName,
    topic,
    lessonsLeft,
    continueLink,
    className,
}) => {
    return (
        <>
            <div
                className={`flex items-center border rounded-xl md:rounded-lg p-2 my-5 shadow-lg lg:shadow-[0px] ${className}`}
            >
                <div className="xl:w-4/5 w-[90%] font-lato space-y-2">
                    <h2 className=" text-lg font-semibold">{topic}</h2>
                    <h1 className="text-[#969696]">{subjectName}</h1>
                    <div className="flex justify-between transition-all duration-300 items-center rounded-full h-[6px] bg-gray-200 w-11/12">
                        <div
                            className="transition-all duration-300 h-[6px] bg-ctaGreen rounded-full"
                            style={{ width: `${progress}` }}
                        ></div>
                    </div>
                    <div className="flex justify-between text-sm w-11/12">
                        <span className=" text-[#797979] ">
                            {lessonsLeft} lessons left
                        </span>
                        <span className="mx-1">{progress} Done</span>
                    </div>
                </div>
                <button>
                    <Link
                        to={continueLink}
                        className="bg-primaryBlue text-white rounded-full px-3 py-2"
                    >
                        Continue
                    </Link>
                </button>
            </div>
        </>
    )
}

export default LessonProgressCard
