import React, { useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import mathIcon from '../../assets/illustrations/calc.png'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'

type SliderProps = {
    subjects: {
        id: number
        name: string
        topics: number
        lessons: number
        hours: number
    }[]
    isOpen: boolean
}

const SliderComponent: React.FC<SliderProps> = ({ subjects, isOpen }) => {
    const sliderRef = useRef<Slider>(null)

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1025, // For larger screens (laptops and desktops)
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                },
            },
            {
                breakpoint: 769, // For tablets and smaller laptops
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                },
            },
            {
                breakpoint: 0, // For small mobile screens
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                },
            },
        ],
    }

    return (
        <div className=" mt-5 lg:mt-16 ">
            {/* Custom Navigation Buttons */}
            <div className="flex justify-between items-center my-10">
                <h1 className="font-medium text-xl">Recommended for You.</h1>
                <div className="space-x-3 xl:space-x-1 lg:mr-10">
                    <button
                        onClick={() => sliderRef.current?.slickPrev()}
                        className="text-2xl cursor-pointer"
                    >
                        <Icon
                            icon="icons8:chevron-left-round"
                            className="w-6"
                        />
                    </button>
                    <button
                        onClick={() => sliderRef.current?.slickNext()}
                        className="text-2xl cursor-pointer"
                    >
                        <Icon
                            icon="icons8:chevron-right-round"
                            className="w-6"
                        />
                    </button>
                </div>
            </div>
            <Slider ref={sliderRef} {...settings}>
                {subjects.map((subject) => (
                    <div
                        className=" pr-4 transition-all duration-300"
                        key={subject.id}
                    >
                        <div className="rounded-2xl transition-all duration-300">
                            {/* <div className="relative overflow-hidden rounded-t-lg"></div> */}
                            <div
                                className="h-[308px] max-w-[441px] 2xl:max-w-[500px] rounded-2xl transition-all duration-300 flex items-end"
                                style={{
                                    backgroundImage: `url(${mathIcon})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                }}
                            >
                                <div className="w-full rounded-2xl bg-white bg-opacity-30 backdrop-blur-2xl p-4">
                                    <p className="flex items-center text-sm text-bodyText my-2">
                                        {subject.topics} Topics
                                        <i className="bx bxs-circle text-[6px] mx-2"></i>{' '}
                                        {subject.lessons} Lessons
                                        <i className="bx bxs-circle text-[6px] mx-2"></i>
                                        {subject.hours} Hours
                                    </p>
                                    {/* Progress Bar */}
                                    <div className="flex items-center">
                                        <div className="flex-1 bg-white rounded-full h-2 relative overflow-hidden mr-2">
                                            <div
                                                className="bg-green-500 h-2 rounded-full"
                                                style={{ width: '20%' }}
                                            ></div>
                                        </div>
                                        <span className="text-bodyText text-sm">
                                            20%
                                        </span>
                                    </div>
                                    <div className=" flex justify-between items-center">
                                        <Link
                                            to={`/dashboard/subjects/${subject.id}`}
                                            className="flex justify-center items-center mt-2 bg-white py-2 px-4 rounded-md w-[146px] text-xs"
                                        >
                                            {subject.name}
                                        </Link>
                                        <Link
                                            to={`/dashboard/subjects/${subject.id}`}
                                            className="flex justify-center items-center mt-2 bg-black text-white py-2 px-4 rounded-md w-[146px] text-xs"
                                        >
                                            Got to Course
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default SliderComponent
