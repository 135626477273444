import React, { useEffect } from 'react'
import avatar from '../../../assets/svg/Avatar.svg'
import StudentRank from '../StudentRank'
import gold_medal from '../../../assets/svg/Gold 2.svg'
import silver_medal from '../../../assets/svg/Silver 1.svg'
import bronze_medal from '../../../assets/svg/Bronze 1.svg'
import MainHeading from '@/components/HeroSection/MainHeading'

type LeaderboardPageProps = {}

const LeaderboardPage: React.FC<LeaderboardPageProps> = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const medalIcons = {
        gold: gold_medal,
        silver: silver_medal,
        bronze: bronze_medal,
    }
    const avatarUrl =
        'https://i0.wp.com/sbcf.fr/wp-content/uploads/2018/03/sbcf-default-avatar.png?w=300&ssl=1'
    const userId = 7
    const students = [
        { id: 1, name: 'Alice', points: 150, profile: avatarUrl, rank: 1 },
        { id: 2, name: 'Bob', points: 120, profile: avatarUrl, rank: 2 },
        { id: 3, name: 'Charlie', points: 100, profile: avatarUrl, rank: 3 },
        { id: 4, name: 'David', points: 90, profile: avatarUrl, rank: 4 },
        { id: 5, name: 'Eva', points: 80, profile: avatarUrl, rank: 5 },
        { id: 6, name: 'Alice', points: 150, profile: avatarUrl, rank: 6 },
        { id: 7, name: 'Bob', points: 120, profile: avatarUrl, rank: 7 },
        { id: 8, name: 'Charlie', points: 100, profile: avatarUrl, rank: 8 },
        { id: 9, name: 'David', points: 90, profile: avatarUrl, rank: 9 },
        { id: 10, name: 'Eva', points: 80, profile: avatarUrl, rank: 10 },
    ]
    return (
        <>
            <section className="p-5 lg:p-10">
                <div>
                    <div className="lg:hidden bg-[#FAFAFA] text-secondaryText rounded-lg p-3 my-5">
                        <MainHeading
                            text="Leaderboard"
                            className=" text-lg font-semibold"
                        ></MainHeading>
                    </div>
                    <div className="lg:hidden">
                        <div>
                            <div className="flex items-center justify-between p-4 my-5 bg-gradient-to-b from-primaryBlue to-[#85C0FF] rounded-lg">
                                <div className=" flex items-center space-x-2">
                                    <img
                                        src={avatar}
                                        className=" w-9 h-9 rounded-full object-cover"
                                    />
                                    <h2 className=" text-sm font-bold text-white text-center">
                                        Jerome Bell
                                    </h2>
                                </div>

                                <h3 className="text-sm font-medium text-white text-center font-lato">
                                    <span className=" block">4500</span>{' '}
                                    <span className=" block">points</span>
                                </h3>
                            </div>
                            <div className="flex items-center justify-between p-4 my-5 mx-3 bg-gradient-to-b from-primaryBlue to-[#85C0FF] rounded-lg">
                                <div className=" flex items-center space-x-2">
                                    <img
                                        src={avatar}
                                        className=" w-9 h-9 rounded-full object-cover"
                                    />
                                    <h2 className=" text-sm font-bold text-white text-center">
                                        Jerome Bell
                                    </h2>
                                </div>

                                <h3 className="text-sm font-medium text-white text-center font-lato">
                                    <span className=" block">4500</span>{' '}
                                    <span className=" block">points</span>
                                </h3>
                            </div>
                            <div className="flex items-center justify-between p-4 my-5 mx-5 bg-gradient-to-b from-primaryBlue to-[#85C0FF] rounded-lg">
                                <div className=" flex items-center space-x-2">
                                    <img
                                        src={avatar}
                                        className=" w-9 h-9 rounded-full object-cover"
                                    />
                                    <h2 className=" text-sm font-bold text-white text-center">
                                        Jerome Bell
                                    </h2>
                                </div>

                                <h3 className="text-sm font-medium text-white text-center font-lato">
                                    <span className=" block">4500</span>{' '}
                                    <span className=" block">points</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="lg:flex justify-center hidden bg-primaryBlue rounded-lg pt-10 mx-auto xl:mx-0 xl:w-4/5">
                        <div className="flex justify-evenly w-full pt-16">
                            <div className=" space-y-4">
                                <div className=" flex justify-center">
                                    <img
                                        src={avatar}
                                        className=" w-[70px] h-[70px] rounded-full object-cover"
                                    />
                                </div>
                                <h2 className=" text-sm font-bold text-white text-center">
                                    Jerome Bell
                                </h2>
                                <h3 className="text-xs font-medium text-white text-center">
                                    4500 ptx
                                </h3>
                                <div className="bg-gradient-to-b from-blue-300 to-primaryBlue h-28 w-36 rounded-t-lg flex items-center justify-center text-white font-bold">
                                    <h2>2</h2>
                                </div>
                            </div>
                            <div className=" space-y-4 -mt-8">
                                <div className=" flex justify-center">
                                    <img
                                        src={avatar}
                                        className=" w-[70px] h-[70px] rounded-full object-cover"
                                    />
                                </div>
                                <h2 className=" text-sm font-bold text-white text-center">
                                    Jerome Bell
                                </h2>
                                <h3 className="text-xs font-medium text-white text-center">
                                    4500 ptx
                                </h3>
                                <div className="bg-gradient-to-b from-blue-300 to-primaryBlue h-36 w-36 rounded-t-lg flex items-center justify-center text-white font-bold">
                                    <h2>1</h2>
                                </div>
                            </div>
                            <div className=" space-y-4 mt-5">
                                <div className=" flex justify-center">
                                    <img
                                        src={avatar}
                                        className=" w-[70px] h-[70px] rounded-full object-cover"
                                    />
                                </div>
                                <h2 className=" text-sm font-bold text-white text-center">
                                    Jerome Bell
                                </h2>
                                <h3 className="text-xs font-medium text-white text-center">
                                    4500 ptx
                                </h3>
                                <div className="bg-gradient-to-b from-blue-300 to-to-primaryBlue  h-[89px] w-[140px] rounded-t-lg flex items-center justify-center text-white font-bold">
                                    <h2>3</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" border rounded-lg mx-auto xl:w-4/5 xl:mx-0 my-10">
                        <ul>
                            {students.map((student) => (
                                <StudentRank
                                    key={student.id}
                                    rank={student.rank}
                                    medalIcons={medalIcons}
                                    name={student.name}
                                    points={student.points}
                                    profile={student.profile}
                                    className={`py-4 px-3 border-b ${student.id === userId ? 'border-b-4 border-4  border-primaryBlue' : ''}`}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LeaderboardPage
